// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Operator_operator__ooRMg {\n  font-size: 14px;\n  color: var(--color-primary);\n  padding: 4px;\n  border-bottom: 1px solid #4C506C;\n}", "",{"version":3,"sources":["webpack://./src/components/Settings/SettingsOperators/Operator/Operator.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,2BAAA;EACA,YAAA;EACA,gCAAA;AACF","sourcesContent":[".operator {\n  font-size: 14px;\n  color: var(--color-primary);\n  padding: 4px;\n  border-bottom: 1px solid #4C506C;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"operator": "Operator_operator__ooRMg"
};
export default ___CSS_LOADER_EXPORT___;
