import {createAsyncThunk, createSelector, createSlice} from "@reduxjs/toolkit";
import {toast} from "react-toastify";

import {RootState} from "../store";
import {ApiUrls} from "../constants/urls";
import createHttpRequest from "../utils/http";

interface IInitialState {
    tagGroups: any,
    tags: any,
    tagsLoading: boolean,
    success: any,
    requestTagLoading: boolean,
    requestTagSuccess: any,
    requestGroupLoading: boolean,
    requestGroupSuccess: any,
}

const initialState: IInitialState = {
    tagGroups: null,
    tags: null,
    tagsLoading: false,
    success: null,
    requestTagLoading: false,
    requestTagSuccess: null,
    requestGroupLoading: false,
    requestGroupSuccess: null,
};

// Получение списка групп тегов
export const fetchTagGroups
    = createAsyncThunk<any>("tags/fetchTagGroups", async() => {
        const response = await createHttpRequest({
            method: "GET",
            path: ApiUrls.INCIDENT_DICTIONARIES("INCIDENTS_TAGGROUP.1"),
            errorMessage: "messages:fetch_tag_groups_error",
        });

        return response.data;
    });

// Получение списка тегов
export const fetchTags
    = createAsyncThunk<any>("tags/fetchTags", async() => {
        const response = await createHttpRequest({
            method: "GET",
            path: ApiUrls.TAGS(),
            errorMessage: "messages:fetch_tags_error",
        });

        return response.data;
    });

// Создание группы
export const createGroup = createAsyncThunk<any, any>(
    "tags/createGroup",
    async({data}
    ) => {
        const response = await createHttpRequest({
            method: "POST",
            path: ApiUrls.INCIDENT_DICTIONARIES(),
            data,
            errorMessage: "messages:create_tag_groups_error",
        });

        return response.data;
    });

// Обновление названия группы
export const editGroup = createAsyncThunk<any, any>(
    "tags/editGroup",
    async({id, data}
    ) => {
        const response = await createHttpRequest({
            method: "PUT",
            path: ApiUrls.INCIDENT_DICTIONARY(id),
            data,
            errorMessage: "messages:edit_tag_groups_error",
        });

        return response.data;
    });

// Удаление группы
export const deleteGroup
    = createAsyncThunk<any, any>("tags/deleteGroup", async(id) => {
        const response = await createHttpRequest({
            method: "DELETE",
            path: ApiUrls.INCIDENT_DICTIONARY(id),
            errorMessage: "messages:delete_tag_groups_error",
        });

        return response.data;
    });

// Создание тега
export const createTag = createAsyncThunk<any, any>(
    "tags/createTag",
    async({data}
    ) => {
        const response = await createHttpRequest({
            method: "POST",
            path: ApiUrls.TAGS(),
            data,
            errorMessage: "messages:create_tag_error",
        });

        return response.data;
    });

// Обновление названия тега
export const editTag = createAsyncThunk<any, any>(
    "tags/editTag",
    async({id, data}
    ) => {
        const response = await createHttpRequest({
            method: "PUT",
            path: ApiUrls.TAG(id),
            data,
            errorMessage: "messages:edit_tag_error",
        });

        return response.data;
    });

// Удаление тега
export const deleteTag
    = createAsyncThunk<any, any>("tags/deleteTag", async(id) => {
        const response = await createHttpRequest({
            method: "DELETE",
            path: ApiUrls.TAG(id),
            errorMessage: "messages:delete_tag_error",
        });

        return response.data;
    });

const tags = createSlice({
    reducers: {
        resetSuccess(state) {
            if (state.requestTagSuccess) state.requestTagSuccess = null;
            if (state.requestGroupSuccess) state.requestGroupSuccess = null;
        },
    },
    name: "tags",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchTagGroups.pending, (state) => {
            state.success = null;
            state.tagsLoading = true;
        });
        builder.addCase(fetchTagGroups.fulfilled, (state, {payload}) => {
            state.tagGroups = payload.Result;
            state.success = true;
            state.tagsLoading = false;
        });
        builder.addCase(fetchTagGroups.rejected, (state) => {
            state.success = null;
            state.tagsLoading = false;
        });

        builder.addCase(fetchTags.pending, (state) => {
            state.success = null;
            state.tagsLoading = true;
        });
        builder.addCase(fetchTags.fulfilled, (state, {payload}) => {
            state.tags = payload;
            state.success = true;
            state.tagsLoading = false;
        });
        builder.addCase(fetchTags.rejected, (state) => {
            state.success = null;
            state.tagsLoading = false;
        });

        builder.addCase(createTag.pending, (state) => {
            state.requestTagLoading = true;
            state.requestTagSuccess = null;
        });
        builder.addCase(createTag.fulfilled, (state) => {
            state.requestTagSuccess = true;
            state.requestTagLoading = false;
            toast.success("Тег успешно создан", {position: "bottom-right"});
        });
        builder.addCase(createTag.rejected, (state) => {
            state.requestTagSuccess = null;
            state.requestTagLoading = false;
            toast.error("Не удалось создать тег", {position: "bottom-right"});
        });

        builder.addCase(editTag.pending, (state) => {
            state.requestTagSuccess = null;
            state.requestTagLoading = true;
        });
        builder.addCase(editTag.fulfilled, (state) => {
            state.requestTagSuccess = true;
            state.requestTagLoading = false;
            toast.success("Тег успешно изменен", {position: "bottom-right"});
        });
        builder.addCase(editTag.rejected, (state) => {
            state.requestTagSuccess = null;
            state.requestTagLoading = false;
            toast.error("Не удалось изменить тег", {position: "bottom-right"});
        });

        builder.addCase(deleteTag.pending, (state) => {
            state.requestTagSuccess = null;
            state.requestTagLoading = true;
        });
        builder.addCase(deleteTag.fulfilled, (state) => {
            state.requestTagSuccess = true;
            state.requestTagLoading = false;
            toast.success("Тег успешно удален", {position: "bottom-right"});
        });
        builder.addCase(deleteTag.rejected, (state) => {
            state.requestTagSuccess = null;
            state.requestTagLoading = false;
            toast.error("Не удалось удалить тег", {position: "bottom-right"});
        });

        builder.addCase(createGroup.pending, (state) => {
            state.requestGroupSuccess = null;
            state.requestGroupLoading = true;
        });
        builder.addCase(createGroup.fulfilled, (state) => {
            state.requestGroupSuccess = true;
            state.requestGroupLoading = false;
            toast.success("Группа тегов успешно создана", {position: "bottom-right"});
        });
        builder.addCase(createGroup.rejected, (state) => {
            state.requestGroupSuccess = null;
            state.requestGroupLoading = false;
            toast.error("Не удалось создать группу тегов", {position: "bottom-right"});
        });

        builder.addCase(editGroup.pending, (state) => {
            state.requestGroupSuccess = null;
            state.requestGroupLoading = true;
        });
        builder.addCase(editGroup.fulfilled, (state) => {
            state.requestGroupSuccess = true;
            state.requestGroupLoading = false;
            toast.success("Группа тегов успешно изменена", {position: "bottom-right"});
        });
        builder.addCase(editGroup.rejected, (state) => {
            state.requestGroupSuccess = null;
            state.requestGroupLoading = false;
            toast.error("Не удалось изменить группу тегов", {position: "bottom-right"});
        });

        builder.addCase(deleteGroup.pending, (state) => {
            state.requestGroupSuccess = null;
            state.requestGroupLoading = true;
        });
        builder.addCase(deleteGroup.fulfilled, (state) => {
            state.requestGroupSuccess = true;
            state.requestGroupLoading = false;
            toast.success("Группа тегов успешно удалена", {position: "bottom-right"});
        });
        builder.addCase(deleteGroup.rejected, (state) => {
            state.requestGroupSuccess = null;
            state.requestGroupLoading = false;
            toast.error("Не удалось удалить группу тегов", {position: "bottom-right"});
        });
    },
});

export const {
    resetSuccess,
} = tags.actions;

export default tags.reducer;

const slice = ({tagsReducer}: RootState) => tagsReducer;

export const tagGroupsSelector = createSelector(
    slice,
    ({tagGroups}) => tagGroups
);

export const tagsSelector = createSelector(
    slice,
    ({tags}) => tags
);

export const tagsLoadingSelector = createSelector(
    slice,
    ({tagsLoading}) => tagsLoading
);

export const successSelector = createSelector(
    slice,
    ({success}) => success
);

export const requestTagSuccessSelector = createSelector(
    slice,
    ({requestTagSuccess}) => requestTagSuccess
);

export const requestTagLoadingSelector = createSelector(
    slice,
    ({requestTagLoading}) => requestTagLoading
);

export const requestGroupSuccessSelector = createSelector(
    slice,
    ({requestGroupSuccess}) => requestGroupSuccess
);

export const requestGroupLoadingSelector = createSelector(
    slice,
    ({requestGroupLoading}) => requestGroupLoading
);