import React, {ReactElement} from "react";
import {useSelector} from "react-redux";
import {Navigate} from "react-router-dom";

import {Permission} from "models/Permission";
import {isUserLoadingSelector, userPermissionsSelector, userSelector} from "redux/authSlice";
import {AppUrls} from "constants/urls";
import SpinnerWithOverlay from "../UI/SpinnerWithOverlay";
import {isGrantedAccess} from "utils/permissionsHelper";
import NoAuth from "../pages/NoAuth";

interface IProtectedElementProps {
    element: ReactElement,
    auth: Permission[],
}

const ProtectedElement: React.FC<IProtectedElementProps> = ({ element, auth}) => {
    const user = useSelector(userSelector);
    const isUserLoading = useSelector(isUserLoadingSelector);
    const userPermissions = useSelector(userPermissionsSelector);

    if (null === user) {
        return isUserLoading
            ? <SpinnerWithOverlay />
            : <Navigate to={AppUrls.AUTH} replace />;
    }

    return isGrantedAccess(userPermissions, auth)
        ? element
        : <NoAuth />;
};

export default ProtectedElement;