import {createAsyncThunk, createSelector, createSlice} from "@reduxjs/toolkit";

import {RootState} from "store";

import createHttpRequest from "../utils/http";
import {ApiUrls} from "../constants/urls";
import StringObjectInfoModel, {StringAdminObjectInfoModel} from "../models/StringObjectInfoModel";

interface IInitialState {
  incidentTypes: StringObjectInfoModel[],
  adminIncidentTypes: StringAdminObjectInfoModel[],
  isLoading: boolean,
}

const initialState: IInitialState = {
    incidentTypes: [],
    adminIncidentTypes: [],
    isLoading: false,
};

export const fetchIncidentTypes = createAsyncThunk<StringObjectInfoModel[]>("incidentType/collection", async() => {
    const response = await createHttpRequest({
        method: "GET",
        path: ApiUrls.INCIDENT_TYPES,
        errorMessage: "messages:fetch_incident_types_error",
    });

    return response.data;
});

export const fetchAdminIncidentTypes = createAsyncThunk<StringObjectInfoModel[]>("adminIncidentType/collection",
    async() => {
        const response = await createHttpRequest({
            method: "GET",
            path: ApiUrls.ADMIN_INCIDENT_TYPES,
            errorMessage: "messages:fetch_incident_types_error",
        });

        return response.data;
    });

const incidentType = createSlice({
    reducers: {
        updateIncidentTypesList(state, {payload}) {
            state.adminIncidentTypes = state.adminIncidentTypes.map(type => {
                if (type.code === payload.code) {
                    return {...type, incidentPriority: payload.priority};
                } else {
                    return type;
                }
            });
        },
    },
    name: "incidentType",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchIncidentTypes.pending, (state) => {
            state.incidentTypes = [];
            state.isLoading = true;
        });
        builder.addCase(fetchIncidentTypes.fulfilled, (state, {payload}) => {
            state.incidentTypes = payload;
            state.isLoading = false;
        });
        builder.addCase(fetchIncidentTypes.rejected, (state) => {
            state.incidentTypes = [];
            state.isLoading = false;
        });

        builder.addCase(fetchAdminIncidentTypes.pending, (state) => {
            state.adminIncidentTypes = [];
            state.isLoading = true;
        });
        builder.addCase(fetchAdminIncidentTypes.fulfilled, (state, {payload}) => {
            state.adminIncidentTypes = payload;
            state.isLoading = false;
        });
        builder.addCase(fetchAdminIncidentTypes.rejected, (state) => {
            state.adminIncidentTypes = [];
            state.isLoading = false;
        });
    },
});

export const {updateIncidentTypesList} = incidentType.actions;

export default incidentType.reducer;

//Селекторы
const slice = ({incidentTypeReducer}: RootState) => incidentTypeReducer;

export const incidentTypesSelector = createSelector(
    slice,
    ({incidentTypes}) => incidentTypes
);

export const adminIncidentTypesSelector = createSelector(
    slice,
    ({adminIncidentTypes}) => adminIncidentTypes
);

export const isIncidentTypesLoadingSelector = createSelector(
    slice,
    ({isLoading}) => isLoading
);

