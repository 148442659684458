import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";

import {TypedDispatch} from "../../../types";
import {
    adminResponsibilityZonesSelector,
    getAdminResponsibilityZone,
    IAdminResponsibilityZones,
    responsibilityZonesIsLoadingSelector,
    responsibilityZonesUpdatePriorities,
    responsibilityZonesUpdatePrioritiesLoadingSelector,
} from "../../../redux/responsibilitySlice";
import {StringAdminObjectInfoModel} from "../../../models/StringObjectInfoModel";
import {IncidentEventPriority} from "../../../models/IncidentEventPriority";

import CrossIcon from "../../Icons/CrossIcon";
import Zone from "../SettingsOperators/Zone";
import Spinner from "../../UI/Spinner/Spinner";
import IncidentTypeWithPriority from "./IncidentTypeWithPriority";
import AlertFillCircleIcon from "../../Icons/Notifications/AlertFillCircleIcon";

import styles from "./SettingsPriorities.module.scss";

const SettingsPriorities = () => {
    const dispatch = useDispatch<TypedDispatch>();
    const {t} = useTranslation("messages");

    const responsibilityZones = useSelector(adminResponsibilityZonesSelector);
    const responsibilityZonesLoading = useSelector(responsibilityZonesIsLoadingSelector);
    const updatePriorityLoading = useSelector(responsibilityZonesUpdatePrioritiesLoadingSelector);
    const isMount = useRef<boolean>(false);

    const [activeZoneId, setActiveZoneId] = useState<null | number>(null);
    const [isActiveZoneLoading, setIsActiveZoneLoading] = useState<boolean>(false);
    // Типы инцидентов для выводв в UI
    const [activeZoneTypes, setActiveZoneTypes] = useState<StringAdminObjectInfoModel[]>([]);
    const [alertVisible, setAlertVisible] = useState<boolean>(false);

    // Набор всех типов инцидентов с приоритетами выбранной зоны для отправки запроса
    // eslint-disable-next-line max-len
    const activeZoneTypesPayload = useRef<{[key: string]: {priority: IncidentEventPriority | null | undefined}} | null>(null);

    useEffect(() => {
        if (!isMount.current && responsibilityZones?.length) {
            setActiveZoneId(responsibilityZones[0]?.id);
            isMount.current = true;
        }
    }, [responsibilityZones]);

    useEffect(() => {
        if (activeZoneId) {
            // Получение данных активной зоны
            setIsActiveZoneLoading(true);
            setActiveZoneTypes([]);
            dispatch(getAdminResponsibilityZone({id: activeZoneId}))
                .then((response) => {
                    const payload = response.payload as IAdminResponsibilityZones;
                    if (payload?.incidentTypes?.incidentTypes) {
                        const incidentTypes = Object.entries(payload?.incidentTypes?.incidentTypes)
                            .map(([key, value]) => {
                                return {
                                    code: key,
                                    name: value.name,
                                    incidentPriority: value.priority,
                                };
                            });

                        setActiveZoneTypes(incidentTypes);
                        activeZoneTypesPayload.current = payload?.incidentTypes?.incidentTypes;
                    } else {
                        toast.error(t("messages:get_priorities_error"), {position: "bottom-right"});
                    }
                })
                .finally(() => {
                    setIsActiveZoneLoading(false);
                });
        }
    }, [activeZoneId]);

    // Редактирование приоритетов выбранной зоны
    const updateActiveZonePriorityHandle = (type: StringAdminObjectInfoModel) => {
        if (activeZoneId && type && type.code) {
            dispatch(responsibilityZonesUpdatePriorities({
                ids: [activeZoneId],
                data: {
                    incidentTypes: {
                        ...activeZoneTypesPayload.current,
                        [type.code]: {
                            priority: type.incidentPriority,
                        },
                    },
                },
            }))
                .then((response) => {
                    //@ts-ignore
                    if (!response.error && type.code) {
                        activeZoneTypesPayload.current = {
                            ...activeZoneTypesPayload.current,
                            [type.code]: {
                                priority: type.incidentPriority,
                            },
                        };
                    } else {
                        throw new Error(t("messages:update_incident_type_priority_error"));
                    }
                })
                .catch((error) => {
                    toast.error(error.message, {position: "bottom-right"});
                });
        }
    };

    useEffect(() => {
        if (localStorage.getItem("priority-alert") !== "none") {
            setAlertVisible(true);
        }
    }, []);

    return (
        <div>
            {responsibilityZonesLoading
                ? <Spinner size={32} className="mt-4"/>
                : <>
                    {alertVisible && (
                        <div className={styles["alert"]}>
                            <div>
                                <AlertFillCircleIcon/>
                                Приоритеты применяются только для новых инцидентов
                            </div>
                            <CrossIcon className={styles["cross"]} onClick={() => {
                                setAlertVisible(false);
                                localStorage.setItem("priority-alert", "none");
                            }}/>
                        </div>
                    )}

                    <div className={styles["priorities"]}>
                        {(updatePriorityLoading || isActiveZoneLoading) && (
                            <div className={styles["loader"]}>
                                <Spinner size={32}/>
                            </div>
                        )}

                        <div className={styles["priorities-zones"]}>
                            <SimpleBar style={{maxHeight: "570px", height: "100%", paddingRight: "20px"}}>
                                {responsibilityZones?.length > 0 && responsibilityZones.map(zone => (
                                    <div key={zone.id}>
                                        <Zone
                                            name={zone.name}
                                            type="priority"
                                            bgColor={JSON.parse(zone?.additionalProperties?.viewJson
                                                || "{\"bgColor\": \"#6342E7\"}").bgColor}
                                            isActive={activeZoneId === zone.id}
                                            onClick={() => {
                                                setActiveZoneId(zone.id);
                                            }}
                                        />
                                    </div>
                                ))}
                            </SimpleBar>
                        </div>
                        <div className={styles["priorities-types"]}>
                            <SimpleBar style={{maxHeight: "570px", height: "100%", paddingRight: "20px"}}>
                                {activeZoneTypes.length > 0 && activeZoneTypes.map(type => (
                                    <div
                                        key={`${type.code}_zone_${activeZoneId}`}
                                        className={styles["priorities-types__item"]}
                                    >
                                        <IncidentTypeWithPriority
                                            type={type}
                                            updateActiveZonePriorityHandle={updateActiveZonePriorityHandle}
                                        />
                                    </div>
                                ))}
                            </SimpleBar>
                        </div>
                    </div>
                </>
            }
        </div>
    );
};

export default React.memo(SettingsPriorities);