import React, {FC} from "react";

import stylesCommon from "../../../styles/Common.module.scss";
import HeaderAuthorization from "../../Header/HeaderAuthorization";

interface ILayoutAuthorizationProps {
    children: React.ReactNode | string
}

const LayoutAuthorization: FC<ILayoutAuthorizationProps> = ({ children}) => {
    return (
        <>
            {/* eslint-disable-next-line react/jsx-no-undef */}
            <HeaderAuthorization/>

            <div className={stylesCommon["main__content"]}>
                {children}
            </div>
        </>
    );
};

export default LayoutAuthorization;