import {Action} from "redux";

import {isBrowser} from "../evironment";
import {TypedDispatch} from "../../types";
import {SignalRService} from "./SignalRService";
import {setSignalRSettings} from "../../redux/commonSlice";
import {fetchLastEvents, upsertLastEventIncident} from "../../redux/eventSlice";
// import {upsertJournal} from "../../redux/journalSlice";
import {upsertEfficiency} from "../../redux/efficiencyJournalSlice";
import {updateRoadObjectVehicleFeaturesCollections} from "../../redux/roadObjectSlice";

// eslint-disable-next-line no-unused-vars
const signalRMiddleware = ({dispatch}: {dispatch: TypedDispatch}) => {
    let hasDisconnected = false;

    // Данные для обновления транспортных средств на карте
    let vehiclesDataQueue: any = [];
    let isFirstVehiclesMessage = true;
    let vehiclesReceivingTimer: ReturnType<typeof setTimeout>;

    // eslint-disable-next-line no-unused-vars
    return (next: (arg0: Action) => any) =>
        async(action: Action) => {
            // register signalR after the user logged in
            if (action.type === "auth/fetchMe/fulfilled" && isBrowser()) {
                const signalRService = SignalRService.getInstance(
                    () => {
                        dispatch(setSignalRSettings({connectionSuccess: true, isDisconnected: false}));
                        // Запросы после восстановления соединения
                        // Пропускаем один запрос
                        if (hasDisconnected) {
                            dispatch(fetchLastEvents({}));
                            hasDisconnected = false;
                        }
                    }
                );
                signalRService.startConnect();

                signalRService.addSubscriber("IncidentEvent", (message) => {
                    const result = JSON.parse(message);
                    // noinspection JSCheckFunctionSignatures
                    dispatch(upsertLastEventIncident(result));
                });

                // Обновление истории изменения инцидентов
                signalRService.addSubscriber("HistoryEvent", (message) => {
                    const result = JSON.parse(message);
                    dispatch(upsertLastEventIncident(result));
                    // dispatch(upsertJournal(result));
                });

                // Обновление дорожных объектов
                signalRService.addSubscriber("RoadObjectEvent", (message) => {
                    const result = JSON.parse(message);
                    // Временно пропускаем сообщения по передвижению машин
                    if (!result?.externalId) {
                        dispatch(upsertEfficiency(result));
                    } else {
                        // Транспортные средства
                        // Делим данные на массивы каждые 3 секунды
                        if (isFirstVehiclesMessage) {
                            // При получении первого сообщения, включаем таймер, по истечение которого
                            // отправляем данные, очищаем очередь, удаляем таймер
                            vehiclesReceivingTimer = setTimeout(() => {
                                dispatch(updateRoadObjectVehicleFeaturesCollections(vehiclesDataQueue));
                                vehiclesDataQueue = [];
                                isFirstVehiclesMessage = true;
                                clearTimeout(vehiclesReceivingTimer);
                            }, 3000);
                            isFirstVehiclesMessage = false;
                        }

                        // Собираем массив из дорожных объектов т/с
                        vehiclesDataQueue.push(result);
                    }
                });

                signalRService.connection?.onclose(() => {
                    dispatch(setSignalRSettings({isDisconnected: true, connectionSuccess: false}));
                    hasDisconnected = true;
                });
            }
            return next(action);
        };
};

export default signalRMiddleware;