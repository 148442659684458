import React, {FC, ReactNode} from "react";

import styles from "./SpinnerWithOverlay.module.scss";

interface ISpinnerWithOverlayProps {
    children?: ReactNode | string,
}

const SpinnerWithOverlay: FC<ISpinnerWithOverlayProps> = ({children}) => {
    return (
        <div className={styles["spinner-overlay"]}>
            <div className={"sk-spinner sk-spinner-double-bounce"}>
                <div className="sk-double-bounce1"/>
                <div className="sk-double-bounce2"/>
            </div>

            {children && <div className={styles["spinner-text"]}>{children}</div>}
        </div>
    );
};

export default SpinnerWithOverlay;