import {createAsyncThunk, createSelector, createSlice} from "@reduxjs/toolkit";
import {toast} from "react-toastify";

import User from "../models/User";
import {RootState} from "../store";
import {ApiUrls} from "../constants/urls";
import createHttpRequest from "../utils/http";
import Pagination from "../models/Pagination";

interface IFetchZoneOperatorsPayload {
    roleId: number | null,
    zoneId?: number | null,
    isLoaderShow?: boolean,
}

interface IUpdateResponsibilityZoneUsersPayload {
    zoneId: number,
    data: {
        usersToAdd: number[],
        usersToRemove: number[],
    }
}

interface IFetchZoneOperatorsResponse {
    data: User[],
    pagination: Pagination;
}

interface IInitialState {
    operators: User[] | null,
    availableOperators: User[] | null,
    loading: boolean,
    operatorsLoading: boolean,
    requestLoading: boolean,
    requestSuccess: boolean,
}

const initialState: IInitialState = {
    operators: null,
    availableOperators: null,
    loading: false,
    operatorsLoading: false,
    requestLoading: false,
    requestSuccess: false,
};

// Получить список операторов
export const fetchZoneOperators
    = createAsyncThunk<IFetchZoneOperatorsResponse, IFetchZoneOperatorsPayload>("zoneOperators/fetchZoneOperators",
        async({roleId, zoneId}) => {
            if (roleId) {
                const response = await createHttpRequest({
                    method: "GET",
                    path: ApiUrls.GET_OPERATORS(roleId, zoneId ?? ""),
                    errorMessage: "messages:fetch_users_error",
                });

                return response.data;
            }
        });

// Получить список свободных операторов
export const fetchAvailableZoneOperators
    // eslint-disable-next-line max-len
    = createAsyncThunk<IFetchZoneOperatorsResponse, {roleId: number}>("zoneOperators/fetchAvailableZoneOperators",
        async({roleId}) => {
            const response = await createHttpRequest({
                method: "GET",
                path: ApiUrls.GET_OPERATORS(roleId),
                errorMessage: "messages:fetch_users_error",
            });

            return response.data;
        });

// Обновить зоны (добавление операторов на зоны)
export const updateResponsibilityZones = createAsyncThunk<any, any>("zoneOperators/updateResponsibilityZones",
    async({data, mode}) => {
        const response = await createHttpRequest({
            method: "POST",
            path: ApiUrls.UPDATE_RESPONSIBILITY_ZONES(mode),
            data: data,
            errorMessage: "messages:update_responsibility_zone_error",
        });

        return response.data;
    });

// Обновить пользователей, которые привязаны к зоне (добавление/удаление)
export const updateResponsibilityZoneUsers =
    createAsyncThunk<null, IUpdateResponsibilityZoneUsersPayload>("zoneOperators/updateResponsibilityZoneUsers",
        async({data, zoneId}) => {
            const response = await createHttpRequest({
                method: "PUT",
                path: ApiUrls.RESPONSIBILITY_ZONE_UPDATE_USERS(zoneId),
                data: JSON.stringify(data),
                errorMessage: "messages:update_responsibility_zone_users_error",
            });

            return response.data;
        });

const zoneOperators = createSlice({
    reducers: {
        resetSuccess(state) {
            state.requestSuccess = false;
        },
    },
    name: "zoneOperators",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchZoneOperators.pending, (state, action) => {
            if (!action.meta.arg.isLoaderShow) state.operatorsLoading = true;
        });
        builder.addCase(fetchZoneOperators.fulfilled, (state, {payload}) => {
            // @ts-ignore
            state.operators = payload.data;
            state.operatorsLoading = false;
        });
        builder.addCase(fetchZoneOperators.rejected, (state) => {
            state.operatorsLoading = false;
        });

        builder.addCase(fetchAvailableZoneOperators.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchAvailableZoneOperators.fulfilled, (state, {payload}) => {
            state.availableOperators = payload.data;
            state.loading = false;
        });
        builder.addCase(fetchAvailableZoneOperators.rejected, (state) => {
            state.loading = false;
        });

        builder.addCase(updateResponsibilityZones.pending, (state) => {
            state.requestLoading = true;
        });
        builder.addCase(updateResponsibilityZones.fulfilled, (state) => {
            state.requestSuccess = true;
            state.requestLoading = false;
            toast.success("Зоны контроля успешно изменены", {position: "bottom-right"});
        });
        builder.addCase(updateResponsibilityZones.rejected, (state) => {
            state.requestLoading = false;
        });
    },
});

export const {
    resetSuccess,
} = zoneOperators.actions;

export default zoneOperators.reducer;

const slice = ({zoneOperatorsReducer}: RootState) => zoneOperatorsReducer;

export const operatorsSelector = createSelector(
    slice,
    ({operators}) => {
        if (operators?.length) {
            return [...operators].sort((a, b) => {
                if (a?.fullName?.toLowerCase() < b?.fullName?.toLowerCase()) return -1;
                if (a?.fullName?.toLowerCase() > b?.fullName?.toLowerCase()) return 1;

                return 0;
            });
        }

        return operators;
    }
);

export const availableOperatorsSelector = createSelector(
    slice,
    ({availableOperators}) => {
        if (availableOperators?.length) {
            return [...availableOperators].sort((a, b) => {
                if (a?.fullName?.toLowerCase() < b?.fullName?.toLowerCase()) return -1;
                if (a?.fullName?.toLowerCase() > b?.fullName?.toLowerCase()) return 1;

                return 0;
            });
        }

        return availableOperators;
    }
);

export const loadingSelector = createSelector(
    slice,
    ({loading}) => loading
);

export const operatorsLoadingSelector = createSelector(
    slice,
    ({operatorsLoading}) => operatorsLoading
);

export const requestLoadingSelector = createSelector(
    slice,
    ({requestLoading}) => requestLoading
);

export const requestSuccessSelector = createSelector(
    slice,
    ({requestSuccess}) => requestSuccess
);