import {createAsyncThunk, createSelector, createSlice} from "@reduxjs/toolkit";

import {RootState} from "store";

import createHttpRequest from "../utils/http";
import {ApiUrls} from "../constants/urls";
import Indicators from "models/Indicators";
import {IncidentEventPriority} from "models/IncidentEventPriority";

interface IInitialState {
    indicators: Indicators,
    isLoading: boolean,
}

const initialState: IInitialState = {
    indicators: {
        cameraCounts: {
            Active: 0,
            Inactive: 0,
            ServiceMode: 0,
        },
        cameraSubTypedCounts: {},
        todayTotalCount: 0,
        todayTypedCounts: {},
        todayIncidents: {
            [IncidentEventPriority.$0]: {},
            [IncidentEventPriority.$1]: {},
            [IncidentEventPriority.$2]: {},
        },
        yesterdayCount: 0,
        statusByTypeIncidentPartitions: {},
        statusPartitions: {
            New: 0,
            Processing: 0,
            Resolved: 0,
            Rejected: 0,
        },
    },
    isLoading: false,
};

export const fetchIndicators =
    createAsyncThunk<Indicators, string[] | undefined>("indicators", async(zoneIds) => {
        const response = await createHttpRequest({
            method: "GET",
            path: ApiUrls.INDICATORS(zoneIds),
            errorMessage: "messages:fetch_indicators_error",
        });

        return response.data;
    });

const indicators = createSlice({
    reducers: {},
    name: "indicators",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchIndicators.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchIndicators.fulfilled, (state, {payload}) => {
            state.indicators = payload;
            state.isLoading = false;
        });
        builder.addCase(fetchIndicators.rejected, (state) => {
            state.isLoading = false;
        });
    },
});


export default indicators.reducer;

//Селекторы
const slice = ({indicatorsReducer}: RootState) => indicatorsReducer;

export const indicatorsSelector = createSelector(
    slice,
    ({indicators}) => indicators
);

