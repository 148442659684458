// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Zone_zone__fEqMG {\n  display: flex;\n  align-items: center;\n  padding: 16px;\n  border-radius: 4px;\n  cursor: pointer;\n}\n.Zone_zone--active__H\\+M2N {\n  background-color: var(--plate-nested-background-color);\n}\n.Zone_zone--priority__SKmIV {\n  flex-direction: row-reverse;\n  justify-content: space-between;\n  padding: 8px;\n}\n.Zone_zone__indicator__tbYkz {\n  min-width: 16px;\n  width: 16px;\n  height: 16px;\n  border-radius: 2px;\n  margin-right: 4px;\n}\n.Zone_zone__name__Hq9yP {\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}", "",{"version":3,"sources":["webpack://./src/components/Settings/SettingsOperators/Zone/Zone.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,aAAA;EACA,kBAAA;EACA,eAAA;AACF;AACE;EACE,sDAAA;AACJ;AAEE;EACE,2BAAA;EACA,8BAAA;EACA,YAAA;AAAJ;AAGE;EACE,eAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,iBAAA;AADJ;AAIE;EACE,mBAAA;EACA,gBAAA;EACA,uBAAA;AAFJ","sourcesContent":[".zone {\n  display: flex;\n  align-items: center;\n  padding: 16px;\n  border-radius: 4px;\n  cursor: pointer;\n\n  &--active {\n    background-color: var(--plate-nested-background-color);\n  }\n\n  &--priority {\n    flex-direction: row-reverse;\n    justify-content: space-between;\n    padding: 8px;\n  }\n\n  &__indicator {\n    min-width: 16px;\n    width: 16px;\n    height: 16px;\n    border-radius: 2px;\n    margin-right: 4px;\n  }\n\n  &__name {\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"zone": "Zone_zone__fEqMG",
	"zone--active": "Zone_zone--active__H+M2N",
	"zone--priority": "Zone_zone--priority__SKmIV",
	"zone__indicator": "Zone_zone__indicator__tbYkz",
	"zone__name": "Zone_zone__name__Hq9yP"
};
export default ___CSS_LOADER_EXPORT___;
