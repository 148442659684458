/* eslint-disable max-len */
import React, {FC} from "react";

import {SvgProps} from "../../models/SvgProps";

//import icon from "images/icons/trash.svg";

const TrashIcon: FC<SvgProps> = (
    {
        width = 20,
        height = 20,
        onClick,
    }) => {
    return (
        <svg width={width} height={height} onClick={onClick} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M7.49935 0.833313H12.4993C13.4198 0.833313 14.166 1.57951 14.166 2.49998V3.33331H16.666C17.5865 3.33331 18.3327 4.0795 18.3327 4.99998V6.66665C18.3327 7.58712 17.5865 8.33331 16.666 8.33331H16.5992L15.8327 17.5C15.8327 18.4205 15.0865 19.1666 14.166 19.1666H5.83268C4.91221 19.1666 4.16602 18.4205 4.16889 17.5692L3.39924 8.33331H3.33268C2.41221 8.33331 1.66602 7.58712 1.66602 6.66665V4.99998C1.66602 4.0795 2.41221 3.33331 3.33268 3.33331H5.83268V2.49998C5.83268 1.57951 6.57887 0.833313 7.49935 0.833313ZM3.33268 4.99998H5.83268H14.166H16.666V6.66665H3.33268V4.99998ZM5.07025 8.33331H14.9258L14.1677 17.4308L14.1648 17.5H5.8315L5.07025 8.33331ZM12.4993 2.49998V3.33331H7.49935V2.49998H12.4993Z" fill="currentColor"/>
        </svg>
    );
};

export default TrashIcon;