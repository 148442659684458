import {IPreparedOptionsRequest} from "../../../redux/userSettingsSlice";

import dashView1 from "../../../images/dashboard-views/stat-dash1.png";
import dashViewLight1 from "../../../images/dashboard-views/stat-dash-light1.png";
import dashView2 from "../../../images/dashboard-views/stat-dash2.png";
import dashViewLight2 from "../../../images/dashboard-views/stat-dash-light2.png";
import dashView3 from "../../../images/dashboard-views/stat-dash3.png";
import dashViewLight3 from "../../../images/dashboard-views/stat-dash-light3.png";
import dashView4 from "../../../images/dashboard-views/stat-dash4.png";
import dashViewLight4 from "../../../images/dashboard-views/stat-dash-light4.png";
// import dashView5 from "../../../images/dashboard-views/stat-dash5.png";
// import dashViewLight5 from "../../../images/dashboard-views/stat-dash-light5.png";
import {ICard} from "../SettingsViewPage/SettingsViewPage";

interface ICardSrcTable {
    [key: number]: string
}

// Список карточек для превью, настройки главного экрана
export const DEFAULT_CARD_LIST = [
    {id: 1, order: 1, isVisible: true},
    {id: 2, order: 2, isVisible: true},
    {id: 3, order: 3, isVisible: true},
    {id: 4, order: 4, isVisible: true},
    // {id: 5, order: 5, isVisible: false},
];

// Адрес картинок, которые нужно выводить в превью
export const CARD_SRC_TABLE = (theme: string): ICardSrcTable => {
    const isDarkTheme = theme === "dark";
    return {
        1: isDarkTheme ? dashView1 : dashViewLight1,
        2: isDarkTheme ? dashView2 : dashViewLight2,
        3: isDarkTheme ? dashView3 : dashViewLight3,
        4: isDarkTheme ? dashView4 : dashViewLight4,
        // 5: isDarkTheme ? dashView5 : dashViewLight5,
    };
};

export const previewOptions = (theme: string) => {
    const isDarkTheme = theme === "dark";
    return [
        {
            title: "Ключевые показатели системы",
            text: "Сводка о количестве зафиксированных инцидентов и показатели работы каамер.",
            img: isDarkTheme ? dashView1 : dashViewLight1,
            id: 1,
        },
        {
            title: "Карта",
            text: "Виджет интерактивной карты со слоями, зонами контроля и камерами в них.",
            img: isDarkTheme ? dashView2 : dashViewLight2,
            id: 2,
        },
        {
            title: "Инциденты",
            text: "Интерактивная таблица с краткой информацией об инцидентах.",
            img: isDarkTheme ? dashView3 : dashViewLight3,
            id: 3,
        },
        {
            title: "Информация",
            text: "Подробная информация об инциденте, фото- и видеофайлы и кнопки для его отработки.",
            img: isDarkTheme ? dashView4 : dashViewLight4,
            id: 4,
        },
        // {
        //     title: "Стримы",
        //     text: "Показывает видеопотоки от выбранных камер в реальном времени.",
        //     img: isDarkTheme ? dashView5 : dashViewLight5,
        //     id: 5,
        // },
    ];
};

// Преобразование настроек из объекта в массив
export const parseData = (obj: IPreparedOptionsRequest, setState: any) => {
    if (obj) {
        if (Object.keys(obj).length > 0) {
            // Преобразование настроек в массив
            const preparedArr: ICard[] = [];
            let key;
            for (key in obj) {
                preparedArr[key] = obj[key];
            }
            setState(preparedArr);
        } else {
            setState(DEFAULT_CARD_LIST);
        }
    }
};