// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TagGroupDropdown_dropdown__1ZL3k {\n  background: var(--plate-background-color);\n  padding: 10px;\n  border: 1px solid var(--divider-color);\n  border-radius: 4px;\n}\n.TagGroupDropdown_dropdown__item__kkaMF {\n  padding: 6px;\n  border-radius: 4px;\n  display: flex;\n  align-items: center;\n  font-size: 14px;\n}\n.TagGroupDropdown_dropdown__item__kkaMF svg {\n  margin-right: 10px;\n  color: var(--color-secondary-tint);\n}\n.TagGroupDropdown_dropdown__item__kkaMF:hover {\n  background-color: var(--plate-nested-background-color);\n}", "",{"version":3,"sources":["webpack://./src/components/Settings/SettingsTags/TagGroupDropdown/TagGroupDropdown.module.scss"],"names":[],"mappings":"AAAA;EACE,yCAAA;EACA,aAAA;EACA,sCAAA;EACA,kBAAA;AACF;AACE;EACE,YAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,eAAA;AACJ;AACI;EACE,kBAAA;EACA,kCAAA;AACN;AAEI;EACE,sDAAA;AAAN","sourcesContent":[".dropdown {\n  background: var(--plate-background-color);\n  padding: 10px;\n  border: 1px solid var(--divider-color);\n  border-radius: 4px;\n\n  &__item {\n    padding: 6px;\n    border-radius: 4px;\n    display: flex;\n    align-items: center;\n    font-size: 14px;\n\n    svg {\n      margin-right: 10px;\n      color: var(--color-secondary-tint);\n    }\n\n    &:hover {\n      background-color: var(--plate-nested-background-color);\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown": "TagGroupDropdown_dropdown__1ZL3k",
	"dropdown__item": "TagGroupDropdown_dropdown__item__kkaMF"
};
export default ___CSS_LOADER_EXPORT___;
