import React, {ReactElement} from "react";

import {Permission} from "../models/Permission";
import {isEmpty} from "lodash";
import {Route} from "react-router";
import ProtectedElement from "../components/Security/ProtectedElement";


export interface IProtectedRouteProps{
    path: string,
    element: ReactElement,
    auth?: Permission[],
    title?: string,
    icon?: ReactElement,
}

export function isGrantedAccess(userPermissions: Permission[], auth: Permission[]| undefined):boolean {
    if (!auth || auth.length === 0) {
        return true;
    }

    return userPermissions.some(userPermission => auth.includes(userPermission));
}

export function getProtectedRoute({
    path,
    element,
    auth,
}: IProtectedRouteProps) {
    const protectedElement = auth && !isEmpty(auth)
        ? <ProtectedElement auth={auth} element={element}/>
        : element;

    return <Route
        key={path}
        path={path}
        element={protectedElement}
    />;
}