/* eslint-disable max-len */
import React from "react";

import {SvgProps} from "../../models/SvgProps";

const CrossIcon: React.FC<SvgProps> = ({width= 20, height= 20, onClick, className}) => {
    return (
        <svg
            className={className}
            onClick={onClick}
            width={width}
            height={height}
            viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
        >
            <path fillRule="evenodd" clipRule="evenodd" d="M13.4142 12L19.7782 18.364L18.364 19.7782L12 13.4143L5.63604 19.7782L4.22183 18.364L10.5858 12L4.22183 5.63608L5.63604 4.22187L12 10.5858L18.364 4.22187L19.7782 5.63608L13.4142 12Z" fill="currentColor"/>
        </svg>

    );
};

export default CrossIcon;