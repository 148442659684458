import React, {FC, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {TypedDispatch} from "../../../types";
import {isSidebarShowSelector, setSidebarShow, toggleSidebarShow} from "redux/commonSlice";

import BurgerIcon from "../../Icons/BurgerIcon";
import CrossIcon from "../../Icons/CrossIcon";

import stylesBurger from "./Burger.module.scss";

const HeaderBurger: FC = () => {
    const dispatch = useDispatch<TypedDispatch>();
    const isSidebarShow = useSelector(isSidebarShowSelector);

    // Получение значения отображения сайдабара из LocalStorage
    const getLocalStorageSidebarShowValue = () => {
        return localStorage.getItem("sidebarShow");
    };

    // Запись значения отображения сайдбара в LocalStorage
    const setLocalStorageSidebarShowValue = () => {
        localStorage.setItem("sidebarShow", `${!isSidebarShow}`);
    };

    useEffect(() => {
        const isShow = getLocalStorageSidebarShowValue();

        if (isShow) {
            dispatch(setSidebarShow(isShow === "true"));
        }
    }, [dispatch]);

    const toggleHandler = () => {
        dispatch(toggleSidebarShow());
        setLocalStorageSidebarShowValue();
    };

    return (
        <div className={stylesBurger.burger} onClick={toggleHandler}>
            {isSidebarShow ? <CrossIcon/> : <BurgerIcon/>}
        </div>
    );
};

export default HeaderBurger;