import React, {FC} from "react";
import cn from "classnames";

import styles from "./Zone.module.scss";

interface IZoneProps {
    name: string | null,
    bgColor: string,
    onClick: any,
    isActive: any,
    type?: string
}

const Zone:FC<IZoneProps> = ({name, bgColor, onClick, isActive, type}) => {
    return (
        <div
            className={cn(styles["zone"], {[styles["zone--active"]]: isActive},
                {[styles["zone--priority"]]: type === "priority"})}
            onClick={onClick}
        >
            <div className={styles["zone__indicator"]} style={{backgroundColor: bgColor || "#6342E7"}}/>
            <div className={styles["zone__name"]}>{name}</div>
        </div>
    );
};

export default Zone;