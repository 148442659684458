import React, {FC} from "react";
import {useController} from "react-hook-form";
import cn from "classnames";

import styles from "./Input.module.scss";

// eslint-disable-next-line no-unused-vars
export enum ComponentType {Uncontrolled, Controlled}
// eslint-disable-next-line no-unused-vars
export enum StyleType {Outlined, Filled}

export interface IFormInputTextProps {
    type?: string,
    name: string,
    control: any,
    label?: string,
    placeholder?: string,
    rules?: any,
    disabled?: boolean | null,
    defaultValue?: string,
    className?: string,
    setValue?: any,
    componentType?: ComponentType,
    styleType?: StyleType,
    icon?: React.ReactElement,
    // eslint-disable-next-line no-unused-vars
    onChange?: (value: string) => void,
    withoutWrapper?: boolean,
    onKeyUp?: (_: any) => void
}

const TextFormInput: FC<IFormInputTextProps> = (
    {
        type = "text",
        name,
        control,
        label,
        disabled,
        placeholder,
        rules,
        defaultValue,
        className,
        setValue,
        componentType = ComponentType.Uncontrolled,
        styleType = StyleType.Outlined,
        icon,
        onChange,
        withoutWrapper,
        onKeyUp,
    }) => {

    const isControlled = componentType === ComponentType.Controlled;
    const isFilled = styleType === StyleType.Filled;

    const {
        field: {ref, onBlur, value, onChange: onChangeControlled},
        fieldState: {error},
    } = useController({
        name,
        control,
        rules: {...rules},
        defaultValue: defaultValue || "",
    });

    const InputComponent = <>
        {isControlled
            ? <input
                type={type}
                placeholder={placeholder}
                value={value}
                onBlur={onBlur}
                onChange={(e) => {
                    onChangeControlled(e);
                    onChange && onChange(e.target.value);
                    setValue(name, e.target.value);
                }}
                ref={ref}
                className={withoutWrapper ? className : cn({[styles["error"]]: error}, "form-group__input")}
                disabled={disabled ?? false}
                onKeyUp={onKeyUp}
            />
            : <input
                type={type}
                placeholder={placeholder}
                defaultValue={value}
                onBlur={onBlur}
                onChange={e => {
                    onChangeControlled(e);
                    onChange && onChange(e.target.value);
                }}
                ref={ref}
                className={withoutWrapper ? className : cn({[styles["error"]]: error}, "form-group__input")}
                disabled={disabled ?? false}
                onKeyUp={onKeyUp}
            />
        }
    </>;

    if (withoutWrapper) return InputComponent;

    return (
        <div className={cn(styles["form-group"], className)}>
            <div className={cn(styles["form-container"], {[styles["form-container--filled"]]: isFilled})}>
                {icon}

                {InputComponent}

                {!!label && (
                    <label className={cn(
                        {[styles["error-label"]]: error},
                        {[styles["required"]]: rules?.required?.value},
                        "form-group__label"
                    )}>
                        {label}
                    </label>
                )}
            </div>

            {(error) && <div className={cn(styles["error-text"], {[styles["left"]]: isFilled})}>
                {error.message}
            </div>}
        </div>
    );
};

export default TextFormInput;