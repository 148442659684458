// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SpinnerWithOverlay_spinner-overlay__F-SWU {\n  display: flex;\n  position: fixed;\n  top: 0;\n  left: 0;\n  bottom: 0;\n  right: 0;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  background-color: var(--spinner-overlay-color);\n  z-index: 999999;\n}\n\n.SpinnerWithOverlay_spinner-text__jGZoo {\n  font-size: 24px;\n  font-weight: 500;\n  margin-top: 20px;\n}", "",{"version":3,"sources":["webpack://./src/components/UI/SpinnerWithOverlay/SpinnerWithOverlay.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,eAAA;EACA,MAAA;EACA,OAAA;EACA,SAAA;EACA,QAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;EACA,8CAAA;EACA,eAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,gBAAA;AACF","sourcesContent":[".spinner-overlay {\n  display: flex;\n  position: fixed;\n  top: 0;\n  left: 0;\n  bottom: 0;\n  right: 0;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  background-color: var(--spinner-overlay-color);\n  z-index: 999999;\n}\n\n.spinner-text {\n  font-size: 24px;\n  font-weight: 500;\n  margin-top: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinner-overlay": "SpinnerWithOverlay_spinner-overlay__F-SWU",
	"spinner-text": "SpinnerWithOverlay_spinner-text__jGZoo"
};
export default ___CSS_LOADER_EXPORT___;
