import {configureStore} from "@reduxjs/toolkit";

import rootReducer from "redux/reducers";

import signalRMiddleware from "./utils/signalr/signalRMiddleware";


export const makeStore = (preloadedState: object | null = null) => {
    return configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware({
            serializableCheck: false,
        }).concat(signalRMiddleware),
        ...preloadedState ? {preloadedState} : {},
    });
};

export type RootState = ReturnType<typeof rootReducer>;