import {createSelector, createSlice} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {IMapParams} from "./commonSlice";

export interface IInitialState {
    mapParams: IMapParams | null,
    layout: {
        [key: string]: {
            component: string | null,
            fullHeight: boolean,
            hidden: boolean,
        },
    }
}

export const initialState: IInitialState = {
    mapParams: null,
    layout: {
        IncidentsInProcessing: {
            component: "IncidentsInProcessing",
            fullHeight: true,
            hidden: false,
        },
        IncidentsOnControl: {
            component: "IncidentsOnControl",
            fullHeight: true,
            hidden: false,
        },
    },
};

const dashboardSecondScreen = createSlice({
    reducers: {
        changeLayout(state, {payload}) {
            const layout = state.layout;

            Object.keys(payload).forEach((key: keyof typeof layout) => {
                layout[key] = {...layout[key], ...payload[key]};
            });
        },
    },
    name: "dashboardSecondScreen",
    initialState,
});

export default dashboardSecondScreen.reducer;

export const {
    changeLayout,
} = dashboardSecondScreen.actions;

// Селекторы
const slice = ({dashboardSecondScreenReducer}: RootState) => dashboardSecondScreenReducer;

export const layoutSelector = createSelector(
    slice,
    ({layout}) => layout
);