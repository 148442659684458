import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isArray} from "lodash";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import { transliterate as tr } from "transliteration";

import {TypedDispatch} from "../../../types";
import {incidentTypesSelector} from "../../../redux/incidentTypeSlice";
import {
    createGroup,
    editGroup,
    commentGroupsSelector,
    commentsLoadingSelector,
    commentsSelector, fetchCommentsGroups,
    requestCommentSuccessSelector,
    requestGroupSuccessSelector,
    createComment, editComment,
    resetSuccess, deleteGroup, deleteComment, fetchQuickComments,
} from "../../../redux/commentsSlice";

import {TagGroup as CommentGroup} from "../SettingsTags/TagGroup/TagGroup";
import {Tag as Comment} from "../SettingsTags/Tag/Tag";
import AddButton from "../SettingsTags/AddButton";
import AddInput from "../SettingsTags/AddInput";
import Spinner from "../../UI/Spinner/Spinner";
import AssignGroupForm from "../SettingsTags/AssignTagGroupForm";
import AssignButton from "../SettingsTags/AssignButton";

import tagStyles from "../SettingsTags/SettingsTags.module.scss";
import styles from "./SettingsComments.module.scss";

export interface IComment {
    id: number,
    groupId: number | "default",
    name: string,
}

export interface ICommentGroup {
    id: number | "default",
    title: string,
    count: number,
    data: any,
    commonListComments?: IComment[],
    comments: IComment[]
}

const SettingsComments = () => {
    const dispatch = useDispatch<TypedDispatch>();
    const commentGroupsList = useSelector(commentGroupsSelector);
    const commentsList = useSelector(commentsSelector);
    const commentsLoading = useSelector(commentsLoadingSelector);
    const requestCommentSuccess = useSelector(requestCommentSuccessSelector);
    const requestGroupSuccess = useSelector(requestGroupSuccessSelector);
    const incidentTypes = useSelector(incidentTypesSelector);

    const [groups, setGroups] = useState<ICommentGroup[] | []>([]);
    const [defaultGroups, setDefaultGroups] = useState<ICommentGroup[] | []>([]);

    const [activeGroup, setActiveGroup] = useState<null | number | "default">("default");
    const [activeGroupComments, setActiveGroupComments] = useState<IComment[] | null>();

    const [groupInputVisible, setGroupInputVisible] = useState<boolean>(false);
    const [commentInputVisible, setCommentInputVisible] = useState<boolean>(false);

    const [editableGroup, setEditableGroup] = useState<false | number>(false);
    const [editableComment, setEditableComment] = useState<false | number>(false);

    const [assignCommentGroupId, setAssignCommentGroupId] = useState<number | null>(null);

    const inputRef = useRef(null);

    // Получение данных по комментариям
    useEffect(() => {
        dispatch(fetchCommentsGroups());
        dispatch(fetchQuickComments());
    }, []);

    // Обновление комментариев после crud-операций
    useEffect(() => {
        if (requestCommentSuccess) dispatch(fetchQuickComments());
    }, [requestCommentSuccess]);

    // Обновление групп комментариев после crud-операций
    useEffect(() => {
        if (requestGroupSuccess) {
            dispatch(fetchCommentsGroups());
            if (assignCommentGroupId) setAssignCommentGroupId(null);
        }
    }, [requestGroupSuccess]);

    useEffect(() => {
        if (!commentGroupsList?.find((group: any) => group.Id === activeGroup)) {
            setActiveGroup("default");
        }
    }, [commentGroupsList]);

    // Формирование данных групп тегов
    const prepareData = (groups: any, comments: any) => {
        const preparedGroups = groups.map((group: any) => ({
            id: group.Id,
            data: group.Data,
            title: group.Name,
            count: 0,
            comments: [],
        }));
        const sortedComments = [...comments].sort((a: any, b: any) => a.Id - b.Id);
        const preparedComments = sortedComments.map((comment: any) => ({
            id: comment.Id,
            groupId: comment?.Data?.view?.groupId,
            name: comment.Name,
        }));
        const commonListComments = []; //Комменты, созданные вне группы (для вывода в общий список)
        let commonListCommentsCount = 0; //Кол-во всех комментов
        for (let i = 0; i < preparedComments.length; i++) {
            const selectedGroup = preparedGroups.find((group: any) => group.id === preparedComments[i]?.groupId);
            if (selectedGroup) {
                selectedGroup.comments.push(preparedComments[i]);
                selectedGroup.count += 1;
            } else {
                commonListComments.push(preparedComments[i]);
            }
            commonListCommentsCount +=1;
        }

        setGroups([{
            id: "default",
            title: "Все комментарии",
            count: commonListCommentsCount,
            data: {},
            commonListComments,
            comments: [],
        }, ...preparedGroups]);
        setDefaultGroups(preparedGroups);
    };

    useEffect(() => {
        if (isArray(commentGroupsList) && isArray(commentsList)) {
            prepareData(commentGroupsList, commentsList);
        }
    }, [commentGroupsList, commentsList]);

    useEffect(() => {
        setActiveGroupComments(groups?.find(item => item.id === activeGroup)?.comments);
    }, [activeGroup, groups]);

    const addGroupHandler = (value: string) => {
        dispatch(createGroup({data: {
            data: {
                group: tr(value),
            },
            name: value,
            typeId: "INCIDENTS_QUICKRESPONSEGROUP.1",
        }}));
    };

    const addCommentHandler = (value: string) => {
        dispatch(createComment({data: {
            name: value,
            data: {
                code: value,
                description: value,
                text: tr(value),
                view: {groupId: activeGroup},
            },
            typeId: "INCIDENTS_QUICKRESPONSE.1",
        }}));
    };

    const editGroupHandler = (id: number, value: string) => {
        const currentGroup = groups.find((group: any) => group.id === activeGroup);
        const preparedData = {
            data: currentGroup?.data,
            name: value,
            typeId: "INCIDENTS_QUICKRESPONSEGROUP.1",
        };
        dispatch(editGroup({id, data: preparedData}));
    };

    const editCommentHandler = (id: number, value: string, groupId: number) => {
        dispatch(editComment({id, data: {
            name: value,
            data: {
                code: value,
                description: value,
                text: tr(value),
                view: {groupId: groupId},
            },
            typeId: "INCIDENTS_QUICKRESPONSE.1",
        }}));
    };

    return (
        <div className={tagStyles["tags"]}>
            {commentsLoading ? <Spinner size={32} className="mt-4"/> : (
                assignCommentGroupId
                    ? <AssignGroupForm
                        incidentTypes={incidentTypes}
                        setAssignGroupId={setAssignCommentGroupId}
                        groups={commentGroupsList}
                        activeGroup={assignCommentGroupId}
                        editGroup={editGroup}
                    />
                    : <>
                        <div className={tagStyles["tags-groups"]}>
                            <SimpleBar style={{maxHeight: "500px", height: "100%", paddingRight: "20px"}}>
                                <div>
                                    {groups && groups?.length > 0 ? groups.map(group => (
                                        <CommentGroup
                                            key={group.id}
                                            data={group}
                                            isActive={activeGroup === group?.id}
                                            onClick={() => setActiveGroup(group?.id)}
                                            editGroup={editGroupHandler}
                                            inputRef={inputRef}
                                            setEditableGroup={setEditableGroup}
                                            editableGroup={editableGroup}
                                            requestSuccess={requestGroupSuccess}
                                            resetSuccess={resetSuccess}
                                            deleteGroup={deleteGroup}
                                            type={"comment"}
                                        />
                                    )) : <div className={tagStyles["no-data"]}>Нет групп</div>}
                                    {groupInputVisible && (
                                        <AddInput
                                            placeholder="Введите название группы и нажмите Enter"
                                            ref={inputRef}
                                            addData={addGroupHandler}
                                            type="group"
                                            hideInput={setGroupInputVisible}
                                            requestSuccess={requestCommentSuccess}
                                            requestGroupSuccess={requestGroupSuccess}
                                            resetSuccess={resetSuccess}
                                        />
                                    )}
                                </div>
                            </SimpleBar>
                            <AddButton text={"Добавить группу"} onClick={() => setGroupInputVisible(true)}/>
                        </div>
                        <div className={tagStyles["tags-items"]}>
                            <SimpleBar style={{maxHeight: "500px", height: "500px", paddingRight: "20px"}}>
                                <div>
                                    {groups?.length > 0
                                        ? activeGroup === "default"
                                            ? <>
                                                {(groups[0].commonListComments
                                                            && groups[0].commonListComments?.length > 0)
                                                        && groups[0].commonListComments.map(comment => (
                                                            <Comment
                                                                key={comment.id}
                                                                data={comment}
                                                                editTag={editCommentHandler}
                                                                inputRef={inputRef}
                                                                setEditableTag={setEditableComment}
                                                                editableTag={editableComment}
                                                                placeholder={"Введите комментарий и нажмите Enter"}
                                                                type={"comment"}
                                                                deleteTag={deleteComment}
                                                                resetSuccess={resetSuccess}
                                                                requestSuccess={requestCommentSuccess}
                                                                className={styles["comment"]}
                                                            />
                                                        ))}
                                                {defaultGroups.map(group => (
                                                    <div key={group.id}>
                                                        <div className={tagStyles["tags-items__title"]}>
                                                            {group.title}
                                                        </div>
                                                        {group.id !== "default" && (
                                                            <AssignButton
                                                                data={group}
                                                                onClick={() => {
                                                                    setAssignCommentGroupId(Number(group?.id) ?? 0);
                                                                }}
                                                                className="mt-2"
                                                            />
                                                        )}
                                                        {group?.comments?.length > 0
                                                            ? group.comments.map(comment => (
                                                                <Comment
                                                                    key={comment.id}
                                                                    data={comment}
                                                                    editTag={editCommentHandler}
                                                                    inputRef={inputRef}
                                                                    setEditableTag={setEditableComment}
                                                                    editableTag={editableComment}
                                                                    placeholder={"Введите комментарий и нажмите Enter"}
                                                                    type={"comment"}
                                                                    deleteTag={deleteComment}
                                                                    resetSuccess={resetSuccess}
                                                                    requestSuccess={requestCommentSuccess}
                                                                    className={styles["comment"]}
                                                                />
                                                            ))
                                                            : <div className={tagStyles["no-data"]}>
                                                                    Нет комментариев
                                                            </div>
                                                        }
                                                    </div>
                                                ))}
                                            </>
                                            : activeGroupComments && activeGroupComments?.length > 0
                                                ? <div>
                                                    <AssignButton
                                                        onClick={() => setAssignCommentGroupId(activeGroup)}
                                                        data={groups?.find(item => item.id === activeGroup)}
                                                    />
                                                    {activeGroupComments.map(comment => (
                                                        <Comment
                                                            key={comment.id}
                                                            data={comment}
                                                            editTag={editCommentHandler}
                                                            inputRef={inputRef}
                                                            setEditableTag={setEditableComment}
                                                            editableTag={editableComment}
                                                            placeholder={"Введите комментарий и нажмите Enter"}
                                                            type={"comment"}
                                                            deleteTag={deleteComment}
                                                            resetSuccess={resetSuccess}
                                                            requestSuccess={requestCommentSuccess}
                                                            className={styles["comment"]}
                                                        />
                                                    ))}
                                                </div> : <>
                                                    <AssignButton
                                                        data={groups?.find(item => item.id === activeGroup)}
                                                        onClick={() => setAssignCommentGroupId(activeGroup)}
                                                    />
                                                    <div className={tagStyles["no-data"]}>Нет комментариев</div>
                                                </>
                                        : <div className={tagStyles["no-data"]}>Нет комментариев</div>}
                                    {commentInputVisible && (
                                        <AddInput
                                            placeholder="Введите комментарий и нажмите Enter"
                                            ref={inputRef}
                                            addData={addCommentHandler}
                                            type="comment"
                                            hideInput={setCommentInputVisible}
                                            requestSuccess={requestCommentSuccess}
                                            requestGroupSuccess={requestGroupSuccess}
                                            resetSuccess={resetSuccess}
                                        />
                                    )}
                                </div>
                            </SimpleBar>
                            <AddButton text={"Добавить комментарий"} onClick={() => setCommentInputVisible(true)}/>
                        </div>
                    </>
            )}
        </div>
    );
};

export default SettingsComments;