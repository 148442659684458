import React from "react";

import {CameraIcon} from "models/CameraIcon";
import {EventSource} from "../../models/EventSource";

import Image from "components/Icons/Image";
import staticIcon from "images/icons/device/CAM/static-white.svg";
import staticSmallIcon from "images/icons/device/CAM/static-small-white.svg";
import sphereIcon from "images/icons/device/CAM/sphere-white.svg";
import sphere3Icon from "images/icons/device/CAM/sphere3-white.svg";
import manuallyIcon from "images/icons/device/person-white.svg"; // При ручном добавлении или от 112
import mchs from "images/icons/device/112.svg";

interface IDeviceIconProps {
    deviceType?: string | null;
    deviceSubType?: string | null;
    width?: number,
    height?: number,
}

const DeviceIcon: React.FC<IDeviceIconProps> = (
    {
        deviceType,
        deviceSubType,
        width = 32,
        height = 32,
    }) => {
    let icon: null;
    if (deviceType === "CAM") {
        switch (deviceSubType) {
            case CameraIcon.Static:
                icon = staticIcon;
                break;
            case CameraIcon.StaticSmall:
                icon = staticSmallIcon;
                break;
            case CameraIcon.Sphere:
                icon = sphereIcon;
                break;
            case CameraIcon.Sphere3:
                icon = sphere3Icon;
                break;
            default:
                icon = staticIcon;
        }
    } else {
        switch (deviceSubType) {
            case EventSource.service112:
                icon = mchs;
                break;
            case EventSource.api:
                icon = manuallyIcon;
                break;
            default:
                icon = null;
        }
    }
    if (null === icon) {
        return <>N/A</>;
    }
    return (
        <Image
            src={icon}
            width={width}
            height={height}
            alt={deviceType ?? ""}
        />
    );
};

export default React.memo(DeviceIcon);