import React, {FC, useEffect} from "react";
import cn from "classnames";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import {TypedDispatch} from "../../../types";
import {fetchResponseServices, responseServicesSelector} from "../../../redux/responseServiceSlice";

import Header from "../../DashboardDemonstration/Header/Header";
import Sidebar from "../../Sidebar";

import stylesCommon from "styles/Common.module.scss";

interface ILayoutMainProps {
    children: React.ReactNode | string
    isDemonstrationDashboard: boolean,
}

const LayoutDemonstration: FC<ILayoutMainProps> = ({ children, isDemonstrationDashboard}) => {
    const dispatch = useDispatch<TypedDispatch>();
    const responseServices = useSelector(responseServicesSelector);

    useEffect(() => {
        // Получение списка служб
        if (isEmpty(responseServices)) dispatch(fetchResponseServices());
    }, []);

    return (
        <>
            <Header />

            <div className={stylesCommon["main__content"]}>
                <Sidebar isDemonstrationDashboard={isDemonstrationDashboard} />

                <div className={cn(
                    stylesCommon["dashboard-content"],
                    stylesCommon["dashboard-content--demonstration"]
                )}>
                    {children}
                </div>
            </div>
        </>
    );
};

export default React.memo(LayoutDemonstration);