import React, {FC} from "react";
import cn from "classnames";

import Input from "../Input/Input";

import {CheckboxContainer} from "./styled/CheckboxContainer";
import styles from "./Checkbox.module.scss";

interface ICheckboxProps {
    className?: string,
    ref?: React.Ref<HTMLInputElement>
    name: string,
    label?: string,
    children?: any,
    bgColor?: string,
    bgCheckedColor?: string,
    checked?: boolean
    defaultChecked?: boolean
}

const Checkbox: FC<ICheckboxProps> = React.forwardRef<HTMLInputElement, React.PropsWithChildren<ICheckboxProps>>(
    (props, ref) => {
        const Component: FC<ICheckboxProps> = ({
            className,
            label,
            bgColor,
            bgCheckedColor,
            children,
            defaultChecked,
            ...props}) => (
            <CheckboxContainer $bgColor={bgColor} $bgCheckedColor={bgCheckedColor}>
                <label  className={cn(styles["custom-checkbox"], className)}>
                    <Input {...props} ref={ref} type="checkbox" defaultChecked={defaultChecked}/>
                    <span className={styles["custom-checkbox__inner"]}>
                        {label || children}
                    </span>
                </label>
            </CheckboxContainer>
        );

        return <Component {...props}/>;
    });

Checkbox.displayName = "Checkbox";

export default React.memo(Checkbox);