import React, {FC, useRef} from "react";
import {Popover, Whisper, WhisperInstance} from "rsuite";

import TypeIcon from "../../../Icons/TypeIcon";
import DotsHIcon from "../../../Icons/DotsHIcon";

import styles from "./AssignedIncidentTypes.module.scss";

interface IAssignedIncidentTypesProps {
    data: any,
}

const AssignedIncidentTypes:FC<IAssignedIncidentTypesProps> = ({data}) => {
    const whisperRef = useRef<WhisperInstance>();

    return (
        <div className={styles["group-info"]}>
            <div>
                <div className={styles["group-icons"]}>
                    {data?.length > 0
                        && data.slice(0, 10).map((type: any) => (
                            <TypeIcon classname={styles["group__icon"]} type={type} key={type.code}/>
                        ))}
                </div>
                {data?.slice(10)?.length > 0 && (
                    <Whisper
                        // @ts-ignore
                        ref={whisperRef}
                        placement="bottomEnd"
                        trigger="click"
                        speaker={
                            <Popover
                                arrow={false}
                                className={styles["dropdown-cnt"]}
                            >
                                <div className={styles["group-icons--more"]}>
                                    {data?.map((item: any) => (
                                        <TypeIcon
                                            classname={styles["group__icon"]}
                                            type={item}
                                            key={item.code}
                                        />
                                    ))}
                                </div>
                            </Popover>
                        }
                    >
                        <div
                            className={styles["group-dots"]}
                            onClick={(e) => e.stopPropagation()}
                        >
                            <DotsHIcon/>
                        </div>
                    </Whisper>
                )}
            </div>
        </div>
    );
};

export default AssignedIncidentTypes;