import {IncidentEventStatus} from "./IncidentEventStatus";
import IncidentOperator from "./IncidentOperator";

export interface IAttachment {
    id: string,
    fileName: string,
    contentType: string,
    created: string,
    lastModified: string,
    size: number,
    thumbnail: string,
    ThumbnailBase64: string,
}

export default interface IncidentComment {
    id: string;
    content?: string | null;
    created?: string | null;
    newStatus?: IncidentEventStatus | null;
    responseService?: string | null;
    operator?: IncidentOperator;
    isEdited?: boolean;
    isDeleted?: boolean,
    contentSource?: contentSourceType;
    history?: IHistoryComment[];
    attachments: IAttachment[];
}

export interface IHistoryComment {
    Id: string,
    Action: string,
    OldText: string,
    NewText: string,
    TimeStamp: string,
}

/* eslint-disable no-unused-vars */
export enum contentSourceType {
    Operator = "operator",
    System = "system",
    Service = "responseService",
}

export enum TypeComments {
    Operator = "operator",
    Service = "response-service",
    AllComments = "comments",
    Media = "mediaComments",
    Document = "documentComments"
}

export enum CommentActions {
    Edit = "Edit",
    Delete = "Delete",
    Undelete = "Undelete"
}
