import {createAsyncThunk, createSelector, createSlice} from "@reduxjs/toolkit";
import {toast} from "react-toastify";

import {RootState} from "../store";
import createHttpRequest from "../utils/http";
import {ApiUrls} from "../constants/urls";

const initialState = {
    loading: false,
    success: false,
};

// Обновление приоритета у типа инцидента
export const updateIncidentTypePriority =
    createAsyncThunk<any, any>("event/removeMedia", async({code, data}) => {
        const response = await createHttpRequest({
            method: "PUT",
            path: ApiUrls.ADMIN_INCIDENT_TYPE(code),
            data: data,
            errorMessage: "messages:update_incident_type_priority_error",
        });

        return response.data;
    });

const priorities = createSlice({
    reducers: {},
    name: "priorities",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(updateIncidentTypePriority.pending, (state) => {
            state.success = false;
            state.loading = true;
        });
        builder.addCase(updateIncidentTypePriority.fulfilled, (state) => {
            state.success = true;
            state.loading = false;
            toast.success("Приоритет успешно обновлен", {
                position: "bottom-right",
            });
        });
        builder.addCase(updateIncidentTypePriority.rejected, (state) => {
            state.loading = false;
            toast.error("Не удалось обновить приоритет", {
                position: "bottom-right",
            });
        });
    },
});

export default priorities.reducer;

const slice = ({prioritiesReducer}: RootState) => prioritiesReducer;

export const successSelector = createSelector(
    slice,
    ({success}) => success
);
