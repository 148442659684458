// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AddOperatorsForm_form-title__1mdHy {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n.AddOperatorsForm_form-title__icon__uSMzT {\n  display: flex;\n  color: var(--color-secondary-tint);\n  cursor: pointer;\n}\n.AddOperatorsForm_form__item__YOa7T {\n  padding-bottom: 5px;\n  padding-top: 5px;\n  border-bottom: 1px solid var(--color-secondary-tint);\n}\n\n.AddOperatorsForm_actions__shZ6A {\n  position: relative;\n  margin-top: 30px;\n}\n\n.AddOperatorsForm_text-error__RYQcc {\n  position: absolute;\n  top: -25px;\n  left: 0;\n  width: 100%;\n  color: var(--color-danger-tint);\n  font-size: 12px;\n}", "",{"version":3,"sources":["webpack://./src/components/Settings/SettingsOperators/AddOperatorsForm/AddOperatorsForm.module.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AAAJ;AAEI;EACE,aAAA;EACA,kCAAA;EACA,eAAA;AAAN;AAIE;EACE,mBAAA;EACA,gBAAA;EACA,oDAAA;AAFJ;;AAMA;EACE,kBAAA;EACA,gBAAA;AAHF;;AAMA;EACE,kBAAA;EACA,UAAA;EACA,OAAA;EACA,WAAA;EACA,+BAAA;EACA,eAAA;AAHF","sourcesContent":[".form {\n  &-title {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n\n    &__icon {\n      display: flex;\n      color: var(--color-secondary-tint);\n      cursor: pointer;\n    }\n  }\n\n  &__item {\n    padding-bottom: 5px;\n    padding-top: 5px;\n    border-bottom: 1px solid var(--color-secondary-tint);\n  }\n}\n\n.actions {\n  position: relative;\n  margin-top: 30px;\n}\n\n.text-error {\n  position: absolute;\n  top: -25px;\n  left: 0;\n  width: 100%;\n  color: var(--color-danger-tint);\n  font-size: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form-title": "AddOperatorsForm_form-title__1mdHy",
	"form-title__icon": "AddOperatorsForm_form-title__icon__uSMzT",
	"form__item": "AddOperatorsForm_form__item__YOa7T",
	"actions": "AddOperatorsForm_actions__shZ6A",
	"text-error": "AddOperatorsForm_text-error__RYQcc"
};
export default ___CSS_LOADER_EXPORT___;
