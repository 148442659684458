// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IncidentTypeWithPriority_type__7LF4H {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n.IncidentTypeWithPriority_type-info__Ls6EM {\n  display: flex;\n  align-items: center;\n}\n.IncidentTypeWithPriority_type__icon__l43YS {\n  border-radius: 4px;\n}\n.IncidentTypeWithPriority_type__name__oXATg {\n  margin-left: 12px;\n}\n.IncidentTypeWithPriority_type-priorities__eNjAy {\n  display: flex;\n}\n.IncidentTypeWithPriority_type-priorities__icon__taR2S {\n  padding: 3px 4px;\n  border: 2px solid transparent;\n  border-radius: 5px;\n  margin: 2px;\n  cursor: pointer;\n  opacity: 0.6;\n}\n.IncidentTypeWithPriority_type-priorities__icon--active__WC77C {\n  border-color: var(--color-primary);\n  opacity: 1;\n}\n.IncidentTypeWithPriority_type-priorities__icon__taR2S img {\n  width: 16px;\n  height: 16px;\n}", "",{"version":3,"sources":["webpack://./src/components/Settings/SettingsPriorities/IncidentTypeWithPriority/IncidentTypeWithPriority.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;AACJ;AAEE;EACE,kBAAA;AAAJ;AAGE;EACE,iBAAA;AADJ;AAIE;EACE,aAAA;AAFJ;AAII;EACE,gBAAA;EACA,6BAAA;EACA,kBAAA;EACA,WAAA;EACA,eAAA;EACA,YAAA;AAFN;AAIM;EACE,kCAAA;EACA,UAAA;AAFR;AAKM;EACE,WAAA;EACA,YAAA;AAHR","sourcesContent":[".type {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n\n  &-info {\n    display: flex;\n    align-items: center;\n  }\n\n  &__icon {\n    border-radius: 4px;\n  }\n\n  &__name {\n    margin-left: 12px;\n  }\n\n  &-priorities {\n    display: flex;\n\n    &__icon {\n      padding: 3px 4px;\n      border: 2px solid transparent;\n      border-radius: 5px;\n      margin: 2px;\n      cursor: pointer;\n      opacity: 0.6;\n\n      &--active {\n        border-color: var(--color-primary);\n        opacity: 1;\n      }\n\n      img {\n        width: 16px;\n        height: 16px;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"type": "IncidentTypeWithPriority_type__7LF4H",
	"type-info": "IncidentTypeWithPriority_type-info__Ls6EM",
	"type__icon": "IncidentTypeWithPriority_type__icon__l43YS",
	"type__name": "IncidentTypeWithPriority_type__name__oXATg",
	"type-priorities": "IncidentTypeWithPriority_type-priorities__eNjAy",
	"type-priorities__icon": "IncidentTypeWithPriority_type-priorities__icon__taR2S",
	"type-priorities__icon--active": "IncidentTypeWithPriority_type-priorities__icon--active__WC77C"
};
export default ___CSS_LOADER_EXPORT___;
