import React, {FC} from "react";

import PlusIcon from "../../../Icons/PlusIcon";

import styles from "./AddButton.module.scss";

interface IAddButtonProps {
    text: string,
    onClick: any,
}
const AddButton:FC<IAddButtonProps> = ({text, onClick}) => {
    return (
        <div className={styles["button"]} onClick={onClick}>
            <PlusIcon/>
            {text}
        </div>
    );
};

export default AddButton;