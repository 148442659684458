import {toast} from "react-toastify";

import {isEmpty} from "lodash";

import {isDev} from "utils/evironment";

import i18n from "../i18n";


type apiErrorType = {
    message: string,
    response: responseDataType
};

type responseDataType = {
    data: {
        Message?: string
    }
};

type errorMessageType = string | [string];

const silentErrors = [
    "Network Error",
];


export const apiErrorHandler = async(error: apiErrorType, errorMessage: errorMessageType, silent: boolean) => {
    // eslint-disable-next-line no-console
    isDev() && console.error(error.message);
    const response = error.response;
    let message = error.message;
    if (response?.data?.Message) {
        message = response.data.Message;
    } else if (errorMessage) {
        message = await getTranslatedErrorMessage(errorMessage);
    }

    if (!silent && !silentErrors.includes(message)) {
        toast.error(message, {
            toastId: message,
        });
    }

    return Promise.reject(new Error(message));
};

async function getTranslatedErrorMessage(errorMessage: errorMessageType) {
    if (!i18n) {
        return "Error occurred during request";
    }
    await i18n.loadNamespaces("messages");
    if (!errorMessage || isEmpty(errorMessage)) {
        return i18n.t("messages:common_error");
    }

    if (Array.isArray(errorMessage)) {
        return i18n.t(...errorMessage);
    }

    return i18n.t(errorMessage);
}