import React from "react";
import cn from "classnames";

import SpinnerIcon from "../../Icons/SpinnerIcon";

import styles from "./Spinner.module.scss";

/* eslint-disable no-unused-vars */
enum sizes {
    lg = 20,
    md = 18,
    sm = 16,
    xs = 14,
}

export interface ISpinnerProps {
    size?: keyof typeof sizes | number,
    color?: string,
    centerMode?: boolean,
    className?: string,
}

const Spinner: React.FC<ISpinnerProps> = (
    {
        size = sizes.xs,
        color = "currentColor",
        centerMode= true,
        className,
    }) => {

    return (
        <div className={cn(styles["spinner__wrapper"], {[styles["spinner__wrapper--center"]]: centerMode}, className)}>
            <SpinnerIcon
                size={size}
                color={color}
            />
        </div>
    );
};

export default Spinner;