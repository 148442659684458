import {createAsyncThunk, createSelector, createSlice} from "@reduxjs/toolkit";
import createHttpRequest from "../utils/http";
import {ApiUrls} from "../constants/urls";
import {RootState} from "../store";
import {DEFAULT_EFFICIENCY_JOURNAL_SORT, DEFAULT_PAGE_SIZE} from "../constants/common";
import {buildQuery} from "../utils/query";
import EfficiencyJournalRecord from "../models/EfficiencyJournalRecord";
import Pagination from "../models/Pagination";
import {TicketModel} from "../models/TicketModel";
import EfficiencyJournalInfoModelPagedList from "../models/EfficiencyJournalInfoModelPagedList";

interface IEfficiencyJournalSort {
    field: "roadObjectId",
    direction: "desc" | "asc"
}

export interface IEfficiencyJournalFilters {
    typeName: string[], // Имя типа устройства
    status: string[], // Статус устройства
}

interface IRoadObjectTypes {
    RoadObjectTypeId: number,
    Name: string,
    Code: string,
    DeviceGroups: [],
}

interface IFetchTickets {
    RoadObjects?: number[]
}

interface IInitialState {
    journalData: EfficiencyJournalRecord[],
    tickets: TicketModel[],
    ticketsLoading: boolean,
    cameraTickets: TicketModel[],
    cameraTicketsLoading: boolean,
    loading: boolean,
    pagination: Pagination,
    journalPagination: Pagination,
    filters: IEfficiencyJournalFilters,
    roadObjectTypes: IRoadObjectTypes[], // Типы устройств
}

function getQueryParams(rootState: RootState, param: string) {
    const state = rootState.efficiencyJournal;

    const paginationType = param === "tickets" ?  state.pagination : state.journalPagination;

    return {
        page: paginationType.page,
        pageSize: paginationType.pageSize || DEFAULT_PAGE_SIZE,
        sort: paginationType.sort || DEFAULT_EFFICIENCY_JOURNAL_SORT,
        ...state.filters,
    };
}

const initialState: IInitialState = {
    journalData: [],
    roadObjectTypes: [],
    tickets: [],
    ticketsLoading: false,
    cameraTickets: [],
    cameraTicketsLoading: false,
    loading: false,
    pagination: {
        page: 1,
        firstPage: true,
        lastPage: true,
        pageSize: DEFAULT_PAGE_SIZE,
        totalElements: 0,
        totalPages: 1,
        sort: DEFAULT_EFFICIENCY_JOURNAL_SORT,
    },
    journalPagination: {
        page: 1,
        firstPage: true,
        lastPage: true,
        pageSize: DEFAULT_PAGE_SIZE,
        totalElements: 0,
        totalPages: 1,
        sort: DEFAULT_EFFICIENCY_JOURNAL_SORT,
    },
    filters: {
        typeName: [],
        status: [],
    },
};

// Получить данные для таблицы журнала работоспособности
export const fetchEfficiencyJournal = createAsyncThunk(
    "efficiency/fetchJournalData",
    async(args, {getState}) => {
        const path = buildQuery(ApiUrls.EFFICIENCY_JOURNAL, getQueryParams(getState() as RootState, "journal"));

        const {data} = await createHttpRequest({
            method: "GET",
            path,
            errorMessage: "messages:fetch_efficiency_error",
        });

        return data;
    }
);

// Получение списка заявок на ремонт для выбранных устройств
export const fetchTickets =
    createAsyncThunk<TicketModel[], IFetchTickets>(
        "efficiency/fetchTickets", async({RoadObjects}) => {
            const {data} = await createHttpRequest({
                method: "GET",
                path: ApiUrls.SERVICE_TICKET(RoadObjects),
                errorMessage: "messages:fetchTicketsError",
            });

            return data;
        }
    );

// Получение списка всех заявок на ремонт
export const fetchEfficiencyTickets =
    createAsyncThunk<EfficiencyJournalInfoModelPagedList>(
        "efficiency/fetchEfficiencyTickets", async(args, {getState}) => {
            const path = buildQuery(ApiUrls.SERVICE_TICKET(), getQueryParams(getState() as RootState, "tickets"));
            const {data} = await createHttpRequest({
                method: "GET",
                path,
                errorMessage: "messages:fetchTicketsError",
            });

            return data;
        }
    );

// Получение списка типов устройств
export const fetchRoadObjectTypes =
    createAsyncThunk<IRoadObjectTypes[], Pick<IRoadObjectTypes, "RoadObjectTypeId"> | undefined>(
        "efficiency/fetchRoadObjectTypes", async(args) => {
            const {data} = await createHttpRequest({
                method: "GET",
                path: ApiUrls.GET_ROAD_OBJECT_TYPES(args?.RoadObjectTypeId),
                errorMessage: "messages:fetch_events_error",
            });

            return data;
        }
    );

const efficiencyJournal = createSlice({
    reducers: {
        setPagination(state, {payload}) {
            state.pagination = {
                ...state.pagination,
                ...payload,
            };
        },
        setJournalPagination(state, {payload}) {
            state.journalPagination = {
                ...state.journalPagination,
                ...payload,
            };
        },
        setFilters(state, {payload}) {
            const filtersItem = state.filters?.[payload.filterType as keyof typeof state.filters];
            const nextValue = typeof payload.selectedFilters === "string"
                ? payload.selectedFilters
                : JSON.stringify(payload.selectedFilters);
            const currentValue = typeof filtersItem === "string" ? filtersItem : JSON.stringify(filtersItem);

            if (nextValue !== currentValue) {
                state.filters = {
                    ...state.filters,
                    [payload.filterType]: payload.selectedFilters,
                };
            }
        },
        upsertEfficiency(state, {payload}) {
            // Обновление записей Журнала работоспособности устройств через SignalR
            if (payload.eventModelType === "roadObjectChangeModel") {
                const index = state.journalData.findIndex((device) => device?.id === payload?.roadObjectId);

                if (index > -1) state.journalData[index].status = payload.operationalStatus;
            }
        },
    },
    name: "efficiencyJournal",
    initialState,
    extraReducers: ({addCase}) => {
        addCase(fetchEfficiencyJournal.pending, (state) => {
            state.loading = true;
        });
        addCase(fetchEfficiencyJournal.fulfilled, (state, {payload}) => {
            state.journalData = payload.data;
            state.journalPagination = payload.pagination;
            state.loading = false;
        });
        addCase(fetchEfficiencyJournal.rejected, (state) => {
            state.loading = false;
        });

        addCase(fetchRoadObjectTypes.fulfilled, (state, {payload}) => {
            state.roadObjectTypes = payload;
        });
        // тикеты для устройств
        addCase(fetchTickets.pending, (state) => {
            state.cameraTicketsLoading = true;
        });
        addCase(fetchTickets.fulfilled, (state, {payload}) => {
            // @ts-ignore
            state.cameraTickets = payload.data;
            state.cameraTicketsLoading = false;
        });
        addCase(fetchTickets.rejected, (state) => {
            state.cameraTicketsLoading = false;
        });
        // список всех тикетов
        addCase(fetchEfficiencyTickets.pending, (state) => {
            state.ticketsLoading = true;
        });
        addCase(fetchEfficiencyTickets.fulfilled, (state, {payload}) => {
            state.tickets = payload.data;
            state.pagination = payload.pagination;
            state.ticketsLoading = false;
        });
        addCase(fetchEfficiencyTickets.rejected, (state) => {
            state.ticketsLoading = false;
        });
    },
});

export default efficiencyJournal.reducer;

export const {
    setPagination,
    setJournalPagination,
    setFilters,
    upsertEfficiency,
} = efficiencyJournal.actions;

const slice = ({efficiencyJournal}: RootState) => efficiencyJournal;

export const journalDataSelector = createSelector(
    slice,
    ({journalData}) => journalData,
);

export const journalDataLoadingSelector = createSelector(
    slice,
    ({loading}) => loading,
);

export const journalPaginationSelector = createSelector(
    slice,
    ({journalPagination}) => ({
        ...journalPagination,
        page: Number(journalPagination.page),
        totalElements: Number(journalPagination.totalElements),
        totalPages: Number(journalPagination.totalPages),
    }),
);

export const journalSortSelector = createSelector(
    slice,
    ({journalPagination}) => {
        const[field, direction] = (journalPagination?.sort || DEFAULT_EFFICIENCY_JOURNAL_SORT).split(",");

        return {field, direction} as IEfficiencyJournalSort;
    },
);

export const journalFiltersSelector = createSelector(
    slice,
    ({filters}) => filters,
);

export const roadObjectTypesSelector = createSelector(
    slice,
    ({roadObjectTypes}) => roadObjectTypes,
);

export const ticketsSelector = createSelector(
    slice,
    ({tickets}) => tickets,
);

export const ticketsLoadingSelector = createSelector(
    slice,
    ({ticketsLoading}) => ticketsLoading,
);

export const cameraTicketsSelector = createSelector(
    slice,
    ({cameraTickets}) => cameraTickets,
);

export const cameraTicketsLoadingSelector = createSelector(
    slice,
    ({cameraTicketsLoading}) => cameraTicketsLoading,
);

export const ticketsPaginationSelector = createSelector(
    slice,
    ({pagination}) => ({
        ...pagination,
        page: Number(pagination.page),
        totalElements: Number(pagination.totalElements),
        totalPages: Number(pagination.totalPages),
    }),
);