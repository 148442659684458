import {createAsyncThunk, createSelector, createSlice} from "@reduxjs/toolkit";
import {toast} from "react-toastify";

import {RootState} from "store";
import createHttpRequest from "../utils/http";
import {ApiUrls} from "../constants/urls";
import {IncidentEventPriority} from "../models/IncidentEventPriority";

interface ICreateResponsibilityZonePayload {
    name: string,
    mapRoadObjectId: number,
    additionalProperties?: {
        viewJson: string,
    },
}

export interface ICreateResponsibilityZoneResponse {
    id: number,
}

export interface IResponsibilityZone {
    code: number;
    name: string | null;
    cameraCount: number | null;
    mapRoadObjectId: number | null,
    viewJson: {[key: string]: string},
}

export interface IAdminResponsibilityZones {
    id: number;
    name: string | null;
    mapRoadObjectId: number | null,
    roadObjectIds: null | number[],
    additionalProperties: {
        responsibilityZoneId: number,
        viewJson: null | string,
    },
    incidentTypes: {
        incidentTypes: {
            [key: string]: {
                name: string,
                priority: number,
            },
        },
    },
}

export interface IResponsibilityZonePayload {
    id: number,
    data: {
        id: number,
        name: string,
        mapRoadObjectId?: number,
        roadObjectIds?: number[],
        additionalProperties: {
            responsibilityZoneId?: number,
            viewJson?: string
        }
    }
}

interface IResponsibilityZonesUpdatePrioritiesPayload {
    ids: number[],
    data: {
        incidentTypes: {
            [key: string]: {
                name?: string,
                priority: IncidentEventPriority | null | undefined,
            },
        }
    }
}

interface IInitialState {
    responsibilityTypes: IResponsibilityZone[],
    responsibilityZones: IResponsibilityZone[],
    adminResponsibilityZones: IAdminResponsibilityZones[],
    isLoading: boolean,
    responsibilityZonesIsLoading: boolean,
    responsibilityZonesIsDeleting: boolean,
    responsibilityZonesUpdatePrioritiesLoading: boolean,
}

const initialState: IInitialState = {
    responsibilityTypes: [], // Дубль responsibilityZones. Постепенно удалиться
    responsibilityZones: [],
    adminResponsibilityZones: [],
    isLoading: false,
    responsibilityZonesIsLoading: false,
    responsibilityZonesIsDeleting: false,
    responsibilityZonesUpdatePrioritiesLoading: false,
};

export const fetchResponsibilityTypes = createAsyncThunk<IResponsibilityZone[]>("responsibility/types", async() => {
    const response = await createHttpRequest({
        method: "GET",
        path: ApiUrls.RESPONSIBILITY_ZONES,
        errorMessage: "messages:fetch_responsibility_types_error",
    });

    return response.data;
});

export const fetchResponsibilityZones = createAsyncThunk<IResponsibilityZone[]>("responsibility/zones", async() => {
    const response = await createHttpRequest({
        method: "GET",
        path: ApiUrls.RESPONSIBILITY_ZONES,
        errorMessage: "messages:fetch_responsibility_zones_error",
    });

    return response.data;
});

// Получение зон контроля (admin api)
// eslint-disable-next-line max-len
export const fetchAdminResponsibilityZones = createAsyncThunk<IAdminResponsibilityZones[]>("responsibility/fetchAdminResponsibilityZones",
    async() => {
        const response = await createHttpRequest({
            method: "GET",
            path: ApiUrls.ADMIN_RESPONSIBILITY_ZONES,
            errorMessage: "messages:fetch_responsibility_zones_error",
        });

        return response.data;
    });

// Создание зоны контроля (ответственности)
export const createResponsibilityZone =
    // eslint-disable-next-line max-len
    createAsyncThunk<ICreateResponsibilityZoneResponse, ICreateResponsibilityZonePayload>("roadObject/createResponsibilityZone",
        async(data) => {
            const response = await createHttpRequest({
                method: "POST",
                path: ApiUrls.ADMIN_RESPONSIBILITY_ZONES,
                data: data,
                errorMessage: "messages:create_responsibility_zone_error",
            });

            return response.data;
        });

// Получить данные одной зоны
export const getAdminResponsibilityZone =
    createAsyncThunk<IAdminResponsibilityZones, {id: number}>("responsibility/getAdminResponsibilityZone",
        async({id}) => {
            const response = await createHttpRequest({
                method: "GET",
                path: ApiUrls.ADMIN_RESPONSIBILITY_ZONE(id),
                errorMessage: "messages:get_responsibility_zone_error",
            });

            return response.data;
        });

// Обновить данные одной зоны
export const updateResponsibilityZone =
    createAsyncThunk<IResponsibilityZone, IResponsibilityZonePayload>("responsibility/updateResponsibilityZone",
        async({id, data}) => {

            const response = await createHttpRequest({
                method: "PUT",
                path: ApiUrls.ADMIN_RESPONSIBILITY_ZONE(id),
                data: data,
                errorMessage: "messages:update_responsibility_zone_error",
                headers: {
                    accept: "*/*",
                    "Content-Type": "application/json",
                },
            });

            return response.data;
        });

// Удалить данные одной зоны (мягкое удаление)
export const deleteResponsibilityZone =
    createAsyncThunk<IResponsibilityZone, {id: number}>("responsibility/deleteResponsibilityZone",
        async({id}) => {

            const response = await createHttpRequest({
                method: "DELETE",
                path: ApiUrls.ADMIN_RESPONSIBILITY_ZONE(id),
                errorMessage: "messages:delete_responsibility_zone_error",
            });

            return response.data;
        });

// Обновление приоритетов типов инцидентов у зон контроля
export const responsibilityZonesUpdatePriorities =
    // eslint-disable-next-line max-len
    createAsyncThunk<null, IResponsibilityZonesUpdatePrioritiesPayload>("responsibility/responsibilityZonesUpdatePriorities",
        async({ids, data}) => {
            const response = await createHttpRequest({
                method: "PUT",
                path: ApiUrls.RESPONSIBILITY_ZONES_UPDATE_PRIORITIES(ids),
                data: JSON.stringify(data),
                headers: {
                    accept: "*/*",
                    "Content-Type": "application/json",
                },
                errorMessage: "messages:update_incident_type_priority_error",
            });

            return response.data;
        });

const responsibility = createSlice({
    reducers: {},
    name: "responsibility",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchResponsibilityTypes.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchResponsibilityTypes.fulfilled, (state, {payload}) => {
            state.responsibilityTypes = payload;
            state.isLoading = false;
        });
        builder.addCase(fetchResponsibilityTypes.rejected, (state) => {
            state.responsibilityTypes = [];
            state.isLoading = false;
        });

        builder.addCase(fetchResponsibilityZones.pending, (state) => {
            state.responsibilityZones = [];
            state.responsibilityZonesIsLoading = true;
        });

        builder.addCase(fetchResponsibilityZones.fulfilled, (state, {payload}) => {
            state.responsibilityZones = payload;
            state.responsibilityZonesIsLoading = false;
        });

        builder.addCase(fetchResponsibilityZones.rejected, (state) => {
            state.responsibilityZones = [];
            state.responsibilityZonesIsLoading = false;
        });

        // Получение зон контроля (admin api)
        builder.addCase(fetchAdminResponsibilityZones.pending, (state) => {
            state.responsibilityZonesIsLoading = true;
        });

        builder.addCase(fetchAdminResponsibilityZones.fulfilled, (state, {payload}) => {
            state.adminResponsibilityZones = payload;
            state.responsibilityZonesIsLoading = false;
        });

        builder.addCase(fetchAdminResponsibilityZones.rejected, (state) => {
            state.responsibilityZonesIsLoading = false;
        });

        // Удаление зоны контроля
        builder.addCase(deleteResponsibilityZone.pending, (state) => {
            state.responsibilityZonesIsDeleting = true;
        });
        builder.addCase(deleteResponsibilityZone.fulfilled, (state) => {
            state.responsibilityZonesIsDeleting = false;
            toast.success("Зона контроля успешно удалена!", {position: "bottom-right"});
        });
        builder.addCase(deleteResponsibilityZone.rejected, (state) => {
            state.responsibilityZonesIsDeleting = false;
            toast.error("Ошибка удаления зоны контроля!", {position: "bottom-right"});
        });

        // Обновление приоритетов типов инцидентов у зон контроля
        builder.addCase(responsibilityZonesUpdatePriorities.pending, (state) => {
            state.responsibilityZonesUpdatePrioritiesLoading = true;
        });
        builder.addCase(responsibilityZonesUpdatePriorities.fulfilled, (state) => {
            state.responsibilityZonesUpdatePrioritiesLoading = false;
        });
        builder.addCase(responsibilityZonesUpdatePriorities.rejected, (state) => {
            state.responsibilityZonesUpdatePrioritiesLoading = false;
        });
    },
});

export default responsibility.reducer;

//Селекторы
const slice = ({responsibilityReducer}: RootState) => responsibilityReducer;

// Зоны контроля (отфильтровываем удаленные зоны)
export const responsibilityTypesSelector = createSelector(
    slice,
    ({responsibilityTypes}) => {
        if (responsibilityTypes.length) {
            return responsibilityTypes.filter((type: IResponsibilityZone) => {
                return !type.viewJson?.["isDeleted"];
            });
        }
        return responsibilityTypes;
    }
);

export const isResponsibilityTypesLoadingSelector = createSelector(
    slice,
    ({isLoading}) => isLoading
);

// Зоны контроля
export const responsibilityZonesSelector = createSelector(
    slice,
    ({responsibilityZones}) => {
        // Фильтруем зоны, которые удалялись по старому api
        if (responsibilityZones.length) {
            return responsibilityZones.filter((zone: IResponsibilityZone) => {
                return zone?.viewJson?.["isDeleted"];
            });
        }
        return responsibilityZones;
    }
);

// Зоны контроля (Admin api)
export const adminResponsibilityZonesSelector = createSelector(
    slice,
    ({adminResponsibilityZones}) => {
        // Фильтруем зоны, которые удалялись по старому api
        if (adminResponsibilityZones.length) {
            return adminResponsibilityZones.filter((zone: IAdminResponsibilityZones) => {
                return !JSON.parse(zone.additionalProperties?.viewJson || "{}")?.["isDeleted"];
            });
        }
        return adminResponsibilityZones;
    }
);

export const responsibilityZonesIsLoadingSelector = createSelector(
    slice,
    ({responsibilityZonesIsLoading}) => responsibilityZonesIsLoading
);

export const responsibilityZonesIsDeletingSelector = createSelector(
    slice,
    ({responsibilityZonesIsDeleting}) => responsibilityZonesIsDeleting
);

export const responsibilityZonesUpdatePrioritiesLoadingSelector = createSelector(
    slice,
    ({responsibilityZonesUpdatePrioritiesLoading}) => responsibilityZonesUpdatePrioritiesLoading
);