import React, {FC, useEffect, useState} from "react";
import cn from "classnames";

import Input from "../Input/Input";

import {CheckboxContainer} from "./styled/CheckboxContainer";
import styles from "./Checkbox.module.scss";

interface ICheckboxProps {
    className?: string,
    name: string,
    color?: string,
    label?: string,
    children?: any,
    isChecked?: boolean,
    // eslint-disable-next-line no-unused-vars
    onChange?: (checked?: boolean) => void,
    bgColor?: string,
    bgCheckedColor?: string,
    isDisabled?: boolean,
}

const ControlledCheckbox: FC<ICheckboxProps> = (
    {
        className,
        name,
        color,
        label,
        children,
        isChecked,
        onChange,
        bgColor,
        bgCheckedColor,
        isDisabled,
    }
) => {
    const [checked, setChecked] = useState<boolean | undefined>(false);

    useEffect(() => {
        setChecked(isChecked);
    }, [isChecked]);

    return (
        <CheckboxContainer $bgColor={bgColor} $bgCheckedColor={bgCheckedColor}>
            <label className={cn(styles["custom-checkbox"], className)}>
                <Input
                    name={name}
                    type="checkbox"
                    checked={checked}
                    className={styles["checkbox"]}
                    onChange={() => {
                        setChecked(!checked);
                        onChange?.(!checked);
                    }}
                    disabled={isDisabled}
                />

                <span
                    className={styles["custom-checkbox__inner"]}
                    style={{color}}
                >
                    {label || children}
                </span>
            </label>
        </CheckboxContainer>
    );
};


export default React.memo(ControlledCheckbox);