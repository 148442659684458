/* eslint-disable max-len */
import React from "react";

const WarningIcon: React.FC<object> = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="xMidYMid meet" focusable="false">
            <path fillRule="evenodd" clipRule="evenodd"
                d="M19.5301 20.5036C21.0701 20.5036 22.0301 18.8336 21.2601 17.5036L13.7301 4.49359C12.9601 3.16359 11.0401 3.16359 10.2701 4.49359L2.74012 17.5036C1.97012 18.8336 2.93012 20.5036 4.47012 20.5036H19.5301ZM12.0001 13.5036C11.4501 13.5036 11.0001 13.0536 11.0001 12.5036V10.5036C11.0001 9.95359 11.4501 9.50359 12.0001 9.50359C12.5501 9.50359 13.0001 9.95359 13.0001 10.5036V12.5036C13.0001 13.0536 12.5501 13.5036 12.0001 13.5036ZM11.0001 15.5036V17.5036H13.0001V15.5036H11.0001Z"
                fill="currentColor"/>
        </svg>
    );
};

export default WarningIcon;