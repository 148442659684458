/* eslint-disable max-len */
import React from "react";

import {SvgProps} from "../../../models/SvgProps";

const TabIcon3: React.FC<SvgProps> = ({width= 20, height= 20}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M9.83809 3.10746C9.39505 2.92286 8.88635 2.99738 8.51488 3.30131L5.36715 5.87673C4.84398 6.30477 4.73615 7.06233 5.1191 7.61936L7.81438 11.5398C8.35049 12.3196 9.51351 12.2836 10.0004 11.4721L13.2089 6.12465C13.6169 5.4446 13.3262 4.56083 12.5941 4.2558L9.83809 3.10746ZM7.62835 2.21777C8.39985 1.58654 9.4564 1.43175 10.3766 1.81515L13.1326 2.9635C14.653 3.59701 15.2568 5.43253 14.4094 6.84494L11.2009 12.1924C10.1897 13.8777 7.77417 13.9525 6.66073 12.3329L3.96545 8.4125C3.17008 7.2556 3.39404 5.68221 4.48062 4.79319L7.62835 2.21777Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M9.78157 8.04254C9.22242 7.90276 8.63799 8.14782 8.34576 8.6446L5.18949 14.0103C4.80781 14.6591 5.05532 15.4955 5.72863 15.8321L8.12538 17.0305C8.41334 17.1745 8.74449 17.2065 9.05469 17.1203L15.6017 15.3017C16.2363 15.1255 16.6388 14.5024 16.5386 13.8515L16.0109 10.421C15.9313 9.9036 15.5491 9.48443 15.0413 9.35747L9.78157 8.04254ZM7.13905 7.93478C7.74598 6.903 8.95981 6.39402 10.1211 6.68435L15.3808 7.99927C16.4356 8.26296 17.2293 9.13354 17.3946 10.2081L17.9224 13.6386C18.1304 14.9906 17.2944 16.2845 15.9764 16.6507L9.42939 18.4693C8.78512 18.6482 8.09735 18.5818 7.49928 18.2827L5.10253 17.0843C3.70412 16.3851 3.19007 14.648 3.98278 13.3004L7.13905 7.93478Z" fill="currentColor"/>
        </svg>
    );
};

export default TabIcon3;