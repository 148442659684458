import React, {FC, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import {TypedDispatch} from "../../../types";
import {fetchResponseServices, responseServicesSelector} from "../../../redux/responseServiceSlice";
import {fetchLastEvents, filteredLastEventsByStatusSelector} from "../../../redux/eventSlice";

import Header from "../../Header";
import Sidebar from "../../Sidebar";

import stylesCommon from "styles/Common.module.scss";

interface ILayoutMainProps {
    theme: string,
    headerFilters?: React.ReactNode,
    children: React.ReactNode | string,
    settingsMode?: boolean,
}

const LayoutMain: FC<ILayoutMainProps> = ({theme, headerFilters, children, settingsMode = false}) => {
    const dispatch = useDispatch<TypedDispatch>();
    const responseServices = useSelector(responseServicesSelector);
    const lastEvents = useSelector(filteredLastEventsByStatusSelector);

    useEffect(() => {
        // Получение списка служб
        if (isEmpty(responseServices)) dispatch(fetchResponseServices());
        if (lastEvents?.length < 100) dispatch(fetchLastEvents({})); // Последние инциденты
    }, []);

    return (
        <>
            <Header theme={theme} filters={headerFilters}/>

            <div className={stylesCommon["main__content"]}>
                {!settingsMode && <Sidebar/>}

                <div className={stylesCommon["dashboard-content"]}>
                    {children}
                </div>
            </div>
        </>
    );
};

export default React.memo(LayoutMain);