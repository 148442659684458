/* eslint-disable max-len */
import React from "react";

const LogoIcon = () => {
    return (
        <svg width="159" height="42" viewBox="0 0 159 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M37.6875 28V15.1504H47.5576V28H45.4658V16.9434H39.7969V28H37.6875Z" fill="currentColor"/>
            <path d="M54.6943 15.1504C56.3525 15.1504 57.5625 15.4902 58.3242 16.1699C59.0859 16.8496 59.4668 17.8018 59.4668 19.0264C59.4668 19.583 59.3789 20.1133 59.2031 20.6172C59.0332 21.1152 58.752 21.5576 58.3594 21.9443C57.9668 22.3252 57.4482 22.627 56.8037 22.8496C56.165 23.0723 55.377 23.1836 54.4395 23.1836H53.0684V28H50.959V15.1504H54.6943ZM54.5537 16.8994H53.0684V21.4258H54.2197C54.8818 21.4258 55.4443 21.3496 55.9072 21.1973C56.3701 21.0391 56.7217 20.79 56.9619 20.4502C57.2021 20.1104 57.3223 19.6621 57.3223 19.1055C57.3223 18.3613 57.0967 17.8076 56.6455 17.4443C56.2002 17.0811 55.5029 16.8994 54.5537 16.8994Z"
                fill="currentColor"/>
            <path d="M73.4414 21.5576C73.4414 22.5479 73.3154 23.4502 73.0635 24.2646C72.8174 25.0732 72.4453 25.7705 71.9473 26.3564C71.4492 26.9365 70.8252 27.3848 70.0752 27.7012C69.3252 28.0176 68.4463 28.1758 67.4385 28.1758C66.4131 28.1758 65.5225 28.0176 64.7666 27.7012C64.0166 27.3848 63.3926 26.9336 62.8945 26.3477C62.4023 25.7617 62.0332 25.0615 61.7871 24.2471C61.541 23.4326 61.418 22.5303 61.418 21.54C61.418 20.2158 61.6348 19.0615 62.0684 18.0771C62.5078 17.0869 63.1729 16.3193 64.0635 15.7744C64.96 15.2236 66.0908 14.9482 67.4561 14.9482C68.792 14.9482 69.9023 15.2207 70.7871 15.7656C71.6719 16.3105 72.334 17.0781 72.7734 18.0684C73.2188 19.0527 73.4414 20.2158 73.4414 21.5576ZM63.6328 21.5576C63.6328 22.5479 63.7676 23.4062 64.0371 24.1328C64.3066 24.8535 64.7197 25.4102 65.2764 25.8027C65.8389 26.1895 66.5596 26.3828 67.4385 26.3828C68.3232 26.3828 69.0439 26.1895 69.6006 25.8027C70.1572 25.4102 70.5674 24.8535 70.8311 24.1328C71.0947 23.4062 71.2266 22.5479 71.2266 21.5576C71.2266 20.0518 70.9248 18.874 70.3213 18.0244C69.7236 17.1689 68.7686 16.7412 67.4561 16.7412C66.5771 16.7412 65.8535 16.9346 65.2852 17.3213C64.7227 17.708 64.3066 18.2617 64.0371 18.9824C63.7676 19.6973 63.6328 20.5557 63.6328 21.5576Z"
                fill="currentColor"/>
            <path d="M81.6768 16.7588C81.0615 16.7588 80.5107 16.8701 80.0244 17.0928C79.5439 17.3154 79.1367 17.6377 78.8027 18.0596C78.4688 18.4756 78.2139 18.9824 78.0381 19.5801C77.8623 20.1719 77.7744 20.8398 77.7744 21.584C77.7744 22.5801 77.915 23.4385 78.1963 24.1592C78.4775 24.874 78.9053 25.4248 79.4795 25.8115C80.0537 26.1924 80.7803 26.3828 81.6592 26.3828C82.2041 26.3828 82.7344 26.3301 83.25 26.2246C83.7656 26.1191 84.2988 25.9756 84.8496 25.7939V27.5869C84.3281 27.792 83.8008 27.9414 83.2676 28.0352C82.7344 28.1289 82.1191 28.1758 81.4219 28.1758C80.1094 28.1758 79.0195 27.9033 78.1523 27.3584C77.291 26.8135 76.6465 26.0459 76.2188 25.0557C75.7969 24.0654 75.5859 22.9053 75.5859 21.5752C75.5859 20.6025 75.7207 19.7119 75.9902 18.9033C76.2598 18.0947 76.6523 17.3975 77.168 16.8115C77.6836 16.2197 78.3193 15.7656 79.0752 15.4492C79.8369 15.127 80.707 14.9658 81.6855 14.9658C82.3301 14.9658 82.9629 15.0391 83.584 15.1855C84.2109 15.3262 84.791 15.5283 85.3242 15.792L84.5859 17.5322C84.1406 17.3213 83.6748 17.1396 83.1885 16.9873C82.7021 16.835 82.1982 16.7588 81.6768 16.7588Z"
                fill="currentColor"/>
            <path d="M96.0117 23.1133C96.0117 23.9219 95.9062 24.6396 95.6953 25.2666C95.4844 25.8936 95.1768 26.4238 94.7725 26.8574C94.3682 27.2852 93.8818 27.6133 93.3135 27.8418C92.7451 28.0645 92.1035 28.1758 91.3887 28.1758C90.7207 28.1758 90.1084 28.0645 89.5518 27.8418C88.9951 27.6133 88.5117 27.2852 88.1016 26.8574C87.6973 26.4238 87.3838 25.8936 87.1611 25.2666C86.9385 24.6396 86.8271 23.9219 86.8271 23.1133C86.8271 22.041 87.0117 21.1328 87.3809 20.3887C87.7559 19.6387 88.2891 19.0674 88.9805 18.6748C89.6719 18.2822 90.4951 18.0859 91.4502 18.0859C92.3467 18.0859 93.1377 18.2822 93.8232 18.6748C94.5088 19.0674 95.0449 19.6387 95.4316 20.3887C95.8184 21.1387 96.0117 22.0469 96.0117 23.1133ZM88.9541 23.1133C88.9541 23.8223 89.0391 24.4287 89.209 24.9326C89.3848 25.4365 89.6543 25.8232 90.0176 26.0928C90.3809 26.3564 90.8496 26.4883 91.4238 26.4883C91.998 26.4883 92.4668 26.3564 92.8301 26.0928C93.1934 25.8232 93.46 25.4365 93.6299 24.9326C93.7998 24.4287 93.8848 23.8223 93.8848 23.1133C93.8848 22.4043 93.7998 21.8037 93.6299 21.3115C93.46 20.8135 93.1934 20.4355 92.8301 20.1777C92.4668 19.9141 91.9951 19.7822 91.415 19.7822C90.5596 19.7822 89.9355 20.0693 89.543 20.6436C89.1504 21.2178 88.9541 22.041 88.9541 23.1133Z"
                fill="currentColor"/>
            <path d="M97.8311 22.1641C97.8311 20.752 97.9863 19.5508 98.2969 18.5605C98.6074 17.5703 99.0967 16.7764 99.7646 16.1787C100.433 15.5811 101.3 15.1738 102.366 14.957C103.058 14.7988 103.761 14.6641 104.476 14.5527C105.19 14.4414 105.905 14.333 106.62 14.2275L106.901 16.0469C106.567 16.0879 106.21 16.1377 105.829 16.1963C105.448 16.249 105.064 16.3047 104.678 16.3633C104.297 16.4219 103.931 16.4834 103.579 16.5479C103.233 16.6064 102.923 16.665 102.647 16.7236C102.097 16.8408 101.631 17.0342 101.25 17.3037C100.875 17.5674 100.582 17.9541 100.371 18.4639C100.16 18.9736 100.034 19.6533 99.9932 20.5029H100.107C100.266 20.2627 100.485 20.0225 100.767 19.7822C101.048 19.542 101.388 19.3428 101.786 19.1846C102.19 19.0205 102.65 18.9385 103.166 18.9385C103.975 18.9385 104.66 19.1084 105.223 19.4482C105.791 19.7881 106.222 20.2803 106.515 20.9248C106.813 21.5693 106.963 22.3457 106.963 23.2539C106.963 24.3379 106.77 25.2461 106.383 25.9785C106.002 26.7051 105.469 27.2529 104.783 27.6221C104.098 27.9912 103.298 28.1758 102.384 28.1758C101.681 28.1758 101.048 28.0439 100.485 27.7803C99.9287 27.5166 99.4512 27.1299 99.0527 26.6201C98.6602 26.1045 98.3584 25.4746 98.1475 24.7305C97.9365 23.9805 97.8311 23.125 97.8311 22.1641ZM102.524 26.4795C102.993 26.4795 103.397 26.3799 103.737 26.1807C104.083 25.9814 104.35 25.6592 104.537 25.2139C104.73 24.7686 104.827 24.1797 104.827 23.4473C104.827 22.5449 104.663 21.8359 104.335 21.3203C104.013 20.7988 103.479 20.5381 102.735 20.5381C102.278 20.5381 101.859 20.6406 101.479 20.8457C101.098 21.0508 100.775 21.291 100.512 21.5664C100.248 21.8359 100.061 22.0732 99.9492 22.2783C99.9492 22.8115 99.9873 23.3271 100.063 23.8252C100.146 24.3232 100.28 24.7744 100.468 25.1787C100.661 25.5771 100.925 25.8936 101.259 26.1279C101.593 26.3623 102.015 26.4795 102.524 26.4795Z"
                fill="currentColor"/>
            <path d="M109.336 28V18.2705H111.41V22.1553H113.37C114.284 22.1553 115.037 22.2607 115.629 22.4717C116.221 22.6768 116.66 22.9873 116.947 23.4033C117.234 23.8193 117.378 24.3408 117.378 24.9678C117.378 25.583 117.24 26.1191 116.965 26.5762C116.689 27.0273 116.259 27.3789 115.673 27.6309C115.087 27.877 114.319 28 113.37 28H109.336ZM111.41 26.4795H113.229C113.851 26.4795 114.352 26.3682 114.732 26.1455C115.119 25.917 115.312 25.542 115.312 25.0205C115.312 24.5342 115.134 24.1914 114.776 23.9922C114.425 23.7871 113.909 23.6846 113.229 23.6846H111.41V26.4795ZM118.881 28V18.2705H120.955V28H118.881Z"
                fill="currentColor"/>
            <path d="M131.256 19.8965H128.057V28H126V19.8965H122.818V18.2705H131.256V19.8965Z" fill="currentColor"/>
            <path d="M135.123 18.2705V23.2363C135.123 23.3945 135.117 23.5967 135.105 23.8428C135.1 24.083 135.088 24.335 135.07 24.5986C135.053 24.8564 135.035 25.0938 135.018 25.3105C135 25.5273 134.985 25.6885 134.974 25.7939L139.869 18.2705H142.383V28H140.405V23.0869C140.405 22.8291 140.411 22.5303 140.423 22.1904C140.44 21.8506 140.458 21.5225 140.476 21.2061C140.499 20.8896 140.514 20.6494 140.52 20.4854L135.642 28H133.137V18.2705H135.123Z"
                fill="currentColor"/>
            <path d="M149.229 18.0859C150.097 18.0859 150.841 18.2646 151.462 18.6221C152.083 18.9795 152.561 19.4863 152.895 20.1426C153.229 20.7988 153.396 21.584 153.396 22.498V23.6055H146.9C146.924 24.5488 147.176 25.2754 147.656 25.7852C148.143 26.2949 148.822 26.5498 149.695 26.5498C150.316 26.5498 150.873 26.4912 151.365 26.374C151.863 26.251 152.376 26.0723 152.903 25.8379V27.5166C152.417 27.7451 151.922 27.9121 151.418 28.0176C150.914 28.123 150.311 28.1758 149.607 28.1758C148.652 28.1758 147.812 27.9912 147.085 27.6221C146.364 27.2471 145.799 26.6904 145.389 25.9521C144.984 25.2139 144.782 24.2969 144.782 23.2012C144.782 22.1113 144.967 21.1855 145.336 20.4238C145.705 19.6621 146.224 19.082 146.892 18.6836C147.56 18.2852 148.339 18.0859 149.229 18.0859ZM149.229 19.6416C148.579 19.6416 148.052 19.8525 147.647 20.2744C147.249 20.6963 147.015 21.3145 146.944 22.1289H151.374C151.368 21.6426 151.286 21.2119 151.128 20.8369C150.976 20.4619 150.741 20.1689 150.425 19.958C150.114 19.7471 149.716 19.6416 149.229 19.6416Z"
                fill="currentColor"/>
            <path d="M11.9764 36.3391L13.5357 37.9459L23.1207 28.0687C25.5711 25.5437 27.0845 22.0533 27.0845 18.205C27.0845 10.5018 21.0242 4.25 13.5422 4.25C6.06028 4.25 0 10.5018 0 18.205C0 22.0533 1.51998 25.5505 3.95719 28.062C4.16684 28.278 4.43546 28.3996 4.74994 28.3996C5.35924 28.3996 5.85061 27.8932 5.85061 27.2653C5.85061 26.948 5.71959 26.6577 5.51648 26.4417C3.47892 24.342 2.20791 21.4187 2.20791 18.205C2.20791 11.7642 7.29852 6.51845 13.5488 6.51845C19.8056 6.51845 24.8897 11.7642 24.8897 18.205C24.8897 21.4254 23.6187 24.3487 21.5615 26.4687L11.9764 36.3391Z"
                fill="currentColor"/>
            <path d="M8.07111 21.223H6.28906L12.6507 14.7822H14.4393L8.07111 21.223Z" fill="currentColor"/>
            <path d="M11.4782 21.2229H9.68956L16.0577 14.7821H17.8398L11.4782 21.2229Z" fill="currentColor"/>
            <path d="M15.0556 21.2229H13.2736L19.6352 14.7821H21.4238L15.0556 21.2229Z" fill="currentColor"/>
        </svg>
    );
};

export default LogoIcon;