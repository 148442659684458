import dateFormatter from "date-and-time";
import ru from "date-and-time/locale/ru";
import en from "date-and-time/locale/en";
import moment from "moment";

const defaultTimeZone = "Europe/Moscow";
type localeType = "ru" | "en";

interface IDateOptions {
    tzString?: string | null,
    format?: string | null,
    nullValue?: string | null,
    localeString?: localeType
}

// Параметры по умолчанию
const defaultDateOptions: IDateOptions = {
    tzString: null,
    format: "HH:mm, DD.MM.YYYY",
    nullValue: "-",
    localeString: "ru",
};

/**
 * Форматирование даты
 * @param dateString - Дата
 * @param options - Опции
 * @return string
 */
export function getFormattedDate(
    dateString?: Date | string | null,
    options: IDateOptions = defaultDateOptions,
) {
    options = {...defaultDateOptions, ...options};
    setLocale(options.localeString);
    // Если не передан dateString
    if (null === dateString || typeof dateString === "undefined") {
        return options.nullValue ?? defaultDateOptions.nullValue;
    }

    const convertedDate = convertTZ(dateString, options.tzString);
    return dateFormatter.format(convertedDate, <string>options.format ?? defaultDateOptions.format);
}

function convertTZ(date: string | Date, tzString: string | null | undefined) {
    return new Date((typeof date === "string" ? new Date(date) : date)
        .toLocaleString("en-US", {timeZone: tzString ?? getClientDefaultTimeZone()}));
}

function getClientDefaultTimeZone() {
    if (typeof Intl === "undefined") {
        return defaultTimeZone;
    }

    return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

function setLocale(localeString: localeType | undefined) {
    switch (localeString) {
        case "ru":
            dateFormatter.locale(ru);
            break;
        case "en":
            dateFormatter.locale(en);
            break;
        default:
            // eslint-disable-next-line no-console
            console.error(localeString + "is unsupported locale");
            dateFormatter.locale(ru);
    }
}

// функция для преобразования даты вида string или [0, 24]
export const getMs = (value: any, type = "min") => {
    const today = moment().format("YYYY-MM-DD");
    let date;
    if (typeof value === "number") {
        if (value === 24) {
            date = new Date(`${today}T23:59:59+03:00`).getTime();
        } else {
            date = new Date(`${today}T${
                String(value)
                    .length < 2 
                    ? `0${value}` 
                    : value
            }:${type === "max" ? "59:59" : "00:00"}+03:00`).getTime();
        }
    } else {
        date = new Date(value).getTime();
    }
    return date;
};

export const getDefaultDate = (time:string) => new Date(`${moment().format("YYYY-MM-DD")}T${time}+03:00`).getTime();
