import React from "react";
import ReactDOM from "react-dom/client";
import i18n from "./i18n";
import {makeStore} from "./store";
import {Provider} from "react-redux";

import {BrowserRouter as Router} from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";
import "rsuite/dist/rsuite.min.css";
import "./styles/globals.scss";

import App from "./App";

const store = makeStore();

const root = ReactDOM.createRoot(document.getElementById("root"));
// eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
i18n.on("loaded", (_) => {
    root.render(
        <React.StrictMode>
            <Provider store={store}>
                <Router basename={process.env.PUBLIC_URL}>
                    <App />
                </Router>
            </Provider>
        </React.StrictMode>
    );
});
